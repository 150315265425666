import React, { useEffect, useState } from "react";
import QuestionFlowList from "./questionFlowList";
import {
	FlowScore,
	FrameworkDomain,
	QuestionFlow,
} from "../../../../utils/interfaces";
import createAxiosInstance from "../../../../utils/axiosConfig";
import { useAuth } from "../../../../context/authContext";
import { format } from "date-fns";
import SideScreenLLM from "../sideScreen";
import { useNavigate } from "react-router-dom";

const QuestionsFlow = ({
	frameworkId,
	selectedDomain,
	questionFlows,
	flowScores,
	latestScores,
	handleGoBack,
	handleRefresh,
}: {
	frameworkId: number;
	selectedDomain: FrameworkDomain;
	questionFlows: QuestionFlow[];
	flowScores: FlowScore[];
	latestScores: FlowScore[];
	handleGoBack: () => void;
	handleRefresh: () => void;
}) => {
	const navigate = useNavigate();
	const { userId, logout } = useAuth();
	const [isOpen, setIsOpen] = useState(false);
	const [LLMContent, setLLMContent] = useState<any>("");
	const [currentLLMIndex, setCurrentLLMIndex] = useState<number>(0);
	const [payloads, setPayloads] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [isSideScreenVisible, setIsSideScreenVisible] =
		useState<boolean>(false);

	const [eventSource, setEventSource] =
		useState<ReadableStreamDefaultReader | null>(null);

	const calculateAverageScore = (scores: FlowScore[]) => {
		const totalScore = scores.reduce((sum, score) => sum + score.score, 0);
		return totalScore / questionFlows.length;
	};

	const getLatestDate = (scores: FlowScore[]) => {
		if (scores.length === 0) return "N/A";
		const latestScore = scores.reduce((latest, score) => {
			return new Date(score.score_date) > new Date(latest.score_date)
				? score
				: latest;
		});
		return format(new Date(latestScore.score_date), "MM/dd/yyyy");
	};

	const handleNext = () => {
		if (currentLLMIndex <= payloads.length - 1) {
			setCurrentLLMIndex(currentLLMIndex + 1);
			setLoading(true);
		}
	};

	const handlePrev = () => {
		if (currentLLMIndex !== 0) {
			setCurrentLLMIndex(currentLLMIndex > 0 ? currentLLMIndex - 1 : 0);
			setLoading(true);
		}
	};

	const closeChatHistory = () => {
		setIsOpen(false);
	};

	const fetchDomainBasedUserAnswers = async (
		user_id: number,
		domain_id: number
	) => {
		try {
			const response = await createAxiosInstance().get(
				`/user_answers/${user_id}/domain/${domain_id}`
			);

			console.log(response);
			if (response?.data?.status == 401 || response?.data?.status == 403) {
				// 401 is for expired token
				// 403 is for forbidden access (blocked by GPT.)
				console.log("user token expired");

				logout();
				// window.location.href = "/";
				navigate("/");
				return;
			}
			setPayloads(response.data);
			// return response.data;
		} catch (e: any) {
			console.log(e, "error");
		}
	};

	const fetchLLMResponse = async (flowId: number, flowKey: string) => {
		// if (!isOpen) return;
		// setLoading(true);
		try {
			// send request to /assessment endpoint.
			// show response in markdown Structure (set LLM content state.).

			// Close previous event source if it exists
			if (eventSource) {
				eventSource.cancel();
			}
			const token = localStorage.getItem("token");

			if (payloads.length > 0) {
				const relevantPayload = payloads.find(
					(item: { nist_area: any }) => item.nist_area === flowKey
				);
				const payload = { ...relevantPayload, question_flow_id: flowId };

				// Send the payload to the /assessment endpoint
				const assessmentResponse = await fetch(
					process.env.REACT_APP_SERVER_URL + "/assessment",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({ ...payload }),
					}
				);

				if (assessmentResponse?.body) {
					const reader = assessmentResponse.body.getReader();
					setEventSource(reader);
				}
			}
		} catch (e: any) {
			setLLMContent("");
			console.log(e, "error ");
			// More specific error handling logic here
			if (e instanceof TypeError) {
				// Handle network errors (e.g., network down, CORS issues)
				console.error("Network error:", e);
				return;
			} else {
				// Handle other types of errors
				console.error("Unexpected error:", e);
				return;
			}
		}
	};

	const addToLLMContent = (newContent: string) => {
		setLLMContent((prevContent: any) => `${prevContent}${newContent}`);
	};

	const handleAIButton = (flow: QuestionFlow) => {
		setIsSideScreenVisible(!isSideScreenVisible);
		setIsOpen(true);
		// setLoading(true);
		// setLLMContent("");
		fetchLLMResponse(flow.id, flow.description);
	};

	useEffect(() => {
		if (userId) {
			fetchDomainBasedUserAnswers(userId, selectedDomain.id);
		}
	}, []);

	useEffect(() => {
		if (eventSource) {
			const readStream = async () => {
				// setLoading(true);
				setLLMContent("");

				try {
					setLoading(false);
					while (true) {
						const { done, value } = await eventSource.read();
						if (done) {
							break;
						}

						const buffer = new TextDecoder().decode(value, { stream: true });
						// Split buffer by newline characters
						const lines = buffer.split("\n");
						// buffer = lines.pop() || ""; // Keep the last partial line in buffer
						// Process complete lines
						lines.forEach((line) => {
							if (line.startsWith("data: ")) {
								const dataString = line.substring(6); // Remove 'data: ' prefix
								try {
									const data = JSON.parse(dataString);
									if (data.message) {
										// addToLastMessage(data.message, "left");
										addToLLMContent(data.message);
										if (LLMContent.length > 0) {
											setLoading(false);
										}
									}
								} catch (e) {
									console.error("Error parsing JSON:", e);
									// setErrorCount(errorCount + 1);
									return;
								}
							}
						});
					}
				} catch (e) {
					console.error("Error reading stream:", e);
					setEventSource(null);
					setLoading(false);
				} finally {
					setEventSource(null);
					setLoading(false);
				}
			};
			readStream();
		}
	}, [eventSource]);

	return (
		<>
			<div
				className="homemain d-flex justify-content-center align-items-center min-vh-95 mt-4"
				style={{ position: "relative" }}
			>
				<div className="container ">
					<div className="row justify-content-center">
						<div className="col-md-10 mb-1">
							<div style={{ marginBottom: "15%" }} className="mt-4">
								<div className="mb-4 ">
									<div className="text-center mb-4 mt-4">
										<h1
											className="mb-md-2 text-center text-blue"
											style={{ paddingLeft: "10% !important" }}
										>
											Current {selectedDomain.title} Score is{" "}
											{Number(
												calculateAverageScore(latestScores).toFixed(2)
											).toFixed(2)}
										</h1>

										<div className="d-flex flex-column flex-grow-1">
											<p className="card-text text-secondary">
												{" "}
												{getLatestDate(latestScores) !== "N/A"
													? `As Of ${getLatestDate(latestScores)}`
													: "Get your score by completing the individual assessments"}
											</p>
										</div>
									</div>
									<h2 className="mb-md-2 text-blue">{selectedDomain.title}:</h2>
								</div>
								{questionFlows.map((flow: QuestionFlow, index: number) => {
									console.log(flow.id, flowScores, "filtering haha");

									const filteredScores = flowScores
										.filter((score: any) => score.question_flow_id == flow.id)
										.sort(
											(a: any, b: any) =>
												new Date(b.score_date).getTime() -
												new Date(a.score_date).getTime()
										);

									console.log(filteredScores, "flow scorwa");

									return (
										<>
											<QuestionFlowList
												flow={flow}
												// assessmentHandler={() => beginAssessment(flow)}
												assessmentHandler={() => {
													navigate(
														`/maturityAssessment/${frameworkId}/areas/${selectedDomain.id}/questionflow/${flow.id}/questionnaire`
													);
												}}
												AIButtonHandler={() => handleAIButton(flow)}
												key={index}
												flowScores={filteredScores}
											/>
										</>
									);
								})}
								<div className="text-center" style={{ paddingTop: "2%" }}>
									<div className="btn btn-link" onClick={handleGoBack}>
										<i className="bi bi-arrow-left me-2"></i>Back
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row"></div>
				</div>
			</div>
			<SideScreenLLM
				isOpen={isOpen}
				handleClose={closeChatHistory}
				LLMContent={LLMContent}
				loading={loading}
				handleNext={handleNext}
				handlePrev={handlePrev}
				showPagination={false}
			/>
		</>
	);
};

export default QuestionsFlow;
