import { useState } from "react";
import remarkBreaks from "remark-breaks";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const SideScreenLLM = ({
	isOpen,
	handleClose,
	LLMContent = "",
	loading,
	handleNext,
	handlePrev,
	showPagination,
}: {
	isOpen: boolean;
	handleClose: () => void;
	LLMContent: any;
	loading: boolean;
	handleNext: () => void;
	handlePrev: () => void;
	showPagination: boolean;
}) => {
	// Split the content by text wrapped in **
	const contentParts = LLMContent.split(
		/(?=^#{1,6} |\n(?=\|)|\n(?=-{3,})|(?<=\*\:))/m
	);

	const [visibleParts, setVisibleParts] = useState(2);

	const handleShowMore = () => {
		setVisibleParts((prev) => Math.min(prev + 3, contentParts.length));
	};

	if (!isOpen) return null;
	const closeSideScreen = () => {
		handleClose();
		setVisibleParts(5);
	};
	return (
		<>
			<div
				className="offcanvas-backdrop fade show w-50"
				onClick={closeSideScreen}
			></div>
			<div
				className="offcanvas offcanvas-start show w-50"
				tabIndex={-1}
				id="offcanvasExample"
				aria-labelledby="offcanvasExampleLabel"
			>
				<div className="offcanvas-header">
					<h5 className="offcanvas-title" id="offcanvasExampleLabel">
						Recommendations.
					</h5>
					<button
						type="button"
						className="btn-close"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
						onClick={closeSideScreen}
					></button>
				</div>
				<div className="offcanvas-body scroller">
					{/* <h1>hi there....</h1> */}

					{loading ? (
						<div className="d-flex flex-column justify-content-center align-items-center h-100">
							<div
								className="d-flex justify-content-center align-items-center spinner-border"
								role="status"
							>
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					) : LLMContent.trim() === "" && !loading ? ( // show content not available message if response is empty
						<div
							className="d-flex justify-content-center align-items-center"
							style={{ height: "100%" }}
						>
							<p>No Recommendations available at the moment.</p>
						</div>
					) : (
						<>
							<div className="card-text nignt">
								{/* {contentParts
									.slice(0, visibleParts)
									.map((part: string, index: any) => ( */}
								<ReactMarkdown
									remarkPlugins={[remarkBreaks, remarkGfm]}
									children={contentParts.slice(0, visibleParts).join("")}
									components={{
										li: ({ node, ...props }) => (
											<li style={{ marginBottom: "3%" }} {...props} />
										),
										p: ({ node, ...props }) => (
											<p className="card-text nignt" {...props} />
										),
										table: ({ node, ...props }) => (
											<div className="table-container pb-4">
												<table className="table table-striped" {...props} />
											</div>
										),
										tr: ({ node, ...props }) => (
											<tr className="cursor-pointer" {...props} />
										),
										th: ({ node, ...props }) => (
											<th className="py-4" {...props} />
										),
										td: ({ node, ...props }) => (
											<td className="py-4" {...props} />
										),
									}}
								/>

								{/* } */}
							</div>
							{visibleParts < contentParts.length && (
								<div className="text-center">
									<button
										className="btn btn-link mt-3"
										onClick={handleShowMore}
									>
										Show More
									</button>
								</div>
							)}
							<br />
							<div className="d-flex justify-content-between">
								{showPagination && (
									<div className="mb-3">
										<button
											type="submit"
											className="btn btn-primary w-10"
											onClick={handlePrev}
										>
											Prev
										</button>
									</div>
								)}

								{showPagination && (
									<div className="mb-3">
										<button
											type="submit"
											className="btn btn-primary w-10"
											onClick={handleNext}
										>
											Next
										</button>
									</div>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default SideScreenLLM;
