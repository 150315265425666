import React, { useEffect, useState } from "react";
import { FrameworkCategory, FrameworkDomain } from "../../../utils/interfaces";
import createAxiosInstance from "../../../utils/axiosConfig";
import { useAuth } from "../../../context/authContext";
import Domains from "./domains";
import Card from "./cards";
import ScoreHistory from "./sideScreen/scoreHistory";
import {
	category_score,
	framework_domains,
	score_history_category,
} from "../../../utils/endpoints";
import { useNavigate } from "react-router-dom";

const MaturityAssessment = ({
	frameworks,
}: {
	frameworks: FrameworkCategory[];
}) => {
	const { userId, userData, logout } = useAuth();
	const navigate = useNavigate();
	const [selectedFramework, setSelectedFramework] =
		useState<FrameworkCategory | null>(null);
	const [domains, setDomains] = useState<FrameworkDomain[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false); // New loading state

	const [categoryScore, setCategoryScore] = useState<any>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [historyContent, setHistoryContent] = useState<{
		last_month: any[];
		last_week: any[];
		last_quarter: any[];
	}>({
		last_month: [],
		last_week: [],
		last_quarter: [],
	});
	// const navigate = useNavigate();

	const handleHistoryClose = () => {
		setIsOpen(false);
	};

	const handleHistoryOpen = (categoryId: number) => {
		setIsOpen(true);
		fetchScoreHistory(categoryId);
	};

	const fetchScoreHistory = async (categoryId: number) => {
		// if (!userId) window.location.href = "/";
		setIsLoading(true);
		try {
			const scoreHistory = await createAxiosInstance().get(
				score_history_category(userId || 0, categoryId)
			);
			console.log(scoreHistory, "llm history");
			if (scoreHistory.status == 401 || scoreHistory.status == 403) {
				// 401 is for expired token
				// 403 is for forbidden access (blocked by GPT.)
				console.log("user token expired");
				logout();
				navigate("/");
				// window.location.href = "/";
				return;
			}

			setHistoryContent({
				last_week: scoreHistory?.data.last_week.reverse(),
				last_month: scoreHistory?.data.last_month.reverse(),
				last_quarter: scoreHistory?.data.last_quarter.reverse(),
			});
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};

	const handleFrameworkSelect = (framework: FrameworkCategory) => {
		setSelectedFramework(framework);
	};

	useEffect(() => {
		const fetchCategoryScore = async () => {
			try {
				// if (userId !== null) {
				const categoryScore = await createAxiosInstance().get(
					category_score(userId || 0)
				);
				console.log(categoryScore, "category score");
				setCategoryScore(categoryScore?.data);
				if (categoryScore.status == 401 || categoryScore.status == 403) {
					// 401 is for expired token
					// 403 is for forbidden access (blocked by GPT.)
					console.log("user token expired");
					logout();
					navigate("/");
					return;
				}
				// } else {
				// 	// Handle the case where userId is null, e.g., throw an error or return a default value
				// 	window.location.href = "/";
				// }
			} catch (e: any) {
				console.log(e);
			}
		};

		fetchCategoryScore();
	}, []);

	useEffect(() => {
		const fetchDomains = async () => {
			if (!selectedFramework) return;
			try {
				const domains = await createAxiosInstance().get(
					framework_domains(selectedFramework.id)
				);
				if (domains.status == 401 || domains.status == 403) {
					// 401 is for expired token
					// 403 is for forbidden access (blocked by GPT.)
					console.log("use token expired");
					logout();
					navigate("/");
					// window.location.href = "/";
					return;
				}
				setDomains(domains?.data);
			} catch (e) {
				console.log(e);
			}
		};

		fetchDomains();
	}, [selectedFramework]);

	const handleQuit = () => {
		// setSelectedFramework(null);
		window.location.href = "/";
	};

	return !selectedFramework ? (
		<>
			<div className="d-flex homemain justify-content-center align-items-center min-vh-96">
				<div className="container ">
					<div className="row justify-content-center ">
						<div className="col-md-10 mb-1 d-flex flex-column justify-content-end align-items-stretch">
							<h1 className="mb-md-5 mb-4 text-center text-blue">
								Cybersecurity & Privacy Assessments
							</h1>
							<div
								className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"
								style={{ marginBottom: "7%" }}
							>
								{frameworks.map(
									(framework: FrameworkCategory, index: number) => {
										const scores = categoryScore?.find(
											(score: any) =>
												score.category_id === framework.id &&
												score.total_score > 0
										);
										return (
											<Card
												title={framework.title}
												// enabled={framework.is_enabled && framework.is_free}
												enabled={
													framework.is_enabled &&
													((userData?.account_type !== undefined &&
														userData?.account_type > 0) ||
														framework.is_free)
												}
												// callbackFn={() => handleFrameworkSelect(framework)}
												callbackFn={() => {
													navigate(`/maturityAssessment/${framework.id}/areas`);
												}}
												key={`case_cards_${index}`}
												showAIButton={false}
												showHistoryButton={scores?.total_score > 0}
												scoreObject={scores}
												handleHistoryButtonClick={() =>
													handleHistoryOpen(framework.id)
												}
											/>
										);
									}
								)}
							</div>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="text-center" style={{ marginBottom: "3%" , marginTop:"3%"}}>
							<div className="btn btn-link" onClick={handleQuit}>
								<i className="bi bi-arrow-left me-2"></i>Back
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<ScoreHistory
				isOpen={isOpen}
				handleClose={handleHistoryClose}
				loading={isLoading}
				content={historyContent}
			/>
		</>
	) : (
		domains && <Domains domains={domains} handleQuit={handleQuit} />
	);
};

export default MaturityAssessment;
