import React, { useEffect, useState } from "react";
import ChatBody from "../common/chat/chatBody";
import { useChat } from "../../context/chatContext";
import { nist_frameworks, start_chat } from "../../utils/endpoints";
import createAxiosInstance from "../../utils/axiosConfig";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";

const Chat = ({ useCase }: { useCase?: number }) => {
	const { setShowCards, startChat, renderFromHistory } = useChat();

	useEffect(() => {
		if (useCase !== undefined) {
			// setSelectedUseCase(useCase);
			if (!renderFromHistory) {
				startChat(useCase);
			}
			setShowCards(false);
		} else {
			setShowCards(true);
		}
	}, [useCase]);

	return (
		<>
			<ChatBody useCase={useCase} />
		</>
	);
};

export default Chat;
