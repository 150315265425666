import React, { useEffect, useState } from "react";
import LogoIcon from "../../assets/svg/logoIcon";
import { getUseCaseName, useCases } from "../../utils/constants";
import { UseCase } from "../../utils/interfaces";
import axios from "axios";
import { ingest_file, ingest_file_multiple } from "../../utils/endpoints";
import { useNavigate } from "react-router-dom";
import BackButton from "../common/backButton";
import Footer from "../common/footer";
import ChatHistory from "../common/chatHistory";
import createAxiosInstance from "../../utils/axiosConfig";
import Alert from "../common/alerts/alert";
import { useAuth } from "../../context/authContext";

type AlertType =
	| "danger"
	| "success"
	| "primary"
	| "secondary"
	| "warning"
	| "info"
	| "light"
	| "dark";

const IngestData = () => {
	const navigate = useNavigate();
	const { logout } = useAuth();
	const userData = JSON.parse(localStorage.getItem("userData") || "{}");

	const [listFiles, setListFiles] = useState<any[]>([]);
	// Add new state for dropdown visibility
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [errors, setErrors] = useState<any>({});
	const [responseError, setResponseError] = useState<string>("");
	const [alertVisible, setAlertVisible] = useState(false);
	const [alertType, setAlertType] = useState<AlertType>("success");

	const [formData, setFormData] = useState({
		use_case: [] as string[],
		base_path: "",
		requires_enrichment: false,
	});

	const [ingestions, setIngestions] = useState<any[]>([]);
	// const [scheduledIngestions, setScheduledIngestions] = useState<any[]>([]);
	const [ingestionAlerts, setIngestionAlerts] = useState<any[]>([]);

	const validate = (formData: any, setErrors: (errors: any) => void) => {
		const tempErrors: any = {};

		Object.keys(formData).forEach((key) => {
			if (!formData[key as keyof typeof formData]) {
				if (
					formData?.requires_enrichment == false ||
					formData?.requires_enrichment == true
				) {
					return;
				} else {
					tempErrors[key] = `${key} is required`;
				}
			}
		});

		setErrors(tempErrors);
		return Object.keys(tempErrors).length === 0;
	};

	const handleUseCaseChange = (
		e: React.MouseEvent | React.ChangeEvent,
		selectedValue: string
	) => {
		e.stopPropagation(); // Prevent the click from bubbling up
		const currentSelections = [...formData.use_case];
		const index = currentSelections.indexOf(selectedValue);

		if (index === -1) {
			currentSelections.push(selectedValue);
		} else {
			currentSelections.splice(index, 1);
		}

		setFormData({ ...formData, use_case: currentSelections });
		validate({ use_case: currentSelections }, setErrors);
	};

	const handleChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>
	) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
		validate({ [name]: value }, setErrors);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!validate(formData, setErrors)) return;

		const payload = {
			use_case_list: formData.use_case.map((uc) => `use_case_${uc}`),
			base_path: formData.base_path,
			requires_enrichment: formData.requires_enrichment,
		};

		try {
			const axiosInstance = createAxiosInstance();
			const response = await axiosInstance.post(ingest_file_multiple, payload);

			if (response.data?.error) {
				setResponseError(response?.data?.error);
				setAlertVisible(true);
				setAlertType("danger");
			} else if (response?.data?.error_message) {
				setResponseError(response?.data?.error_message);
				setAlertVisible(true);
				setAlertType("danger");
			} else {
				const newIngestions: {
					name: string;
					use_case: any;
					process_id: any;
					status: any;
					timestamp: number;
				}[] = [];

				const newIngestionAlerts: {
					name: string;
					use_case: any;
					process_id: any;
					status: any;
					timestamp: number;
				}[] = [];

				response.data.response_list.forEach((item: any) => {
					const status = item.process_status.toLowerCase();
					let currentIngestionItem = {
						name: `${formData?.base_path?.split("/data/")[1]}`,
						use_case: item.use_case,
						process_id: item.process_id,
						status: item.process_status.toLowerCase(),
						message:
							status == "success"
								? " started successfully"
								: status == "pending"
								? " is now scheduled."
								: status == "failure"
								? " got failed."
								: item.process_status.toLowerCase(),
						timestamp: Date.now(),
					};

					if (status.toLowerCase() !== "failure") {
						newIngestions.push(currentIngestionItem);
						newIngestionAlerts.push(currentIngestionItem);
					} else {
						newIngestionAlerts.push(currentIngestionItem);
					}
				});

				setIngestionAlerts([...ingestionAlerts, ...newIngestionAlerts]);
				setIngestions([...ingestions, ...newIngestions]);
				localStorage.setItem(
					"ingestionData",
					JSON.stringify([...ingestions, ...newIngestions])
				);
				// response.data.response_list.map((item: any) => {
				// 	if (item.process_status.toLowerCase() == "success") {
				// 		let ingestion = {
				// 			name: `${formData?.base_path?.split("/data/")[1]}`,
				// 			use_case: item.use_case,
				// 			process_id: item.process_id,
				// 			status: item.process_status.toLowerCase(),
				// 			timestamp: Date.now(),
				// 		};

				// 		newIngestions.push(ingestion);
				// 		setScheduledIngestions([...scheduledIngestions, ingestion]);
				// 		setResponseError(
				// 			`ingestion for ${getUseCaseName(
				// 				item.use_case
				// 			)} completed successfully.`
				// 		);
				// 		setAlertVisible(true);
				// 		setAlertType("success");
				// 		setFormData({
				// 			use_case: [],
				// 			base_path: "",
				// 			requires_enrichment: false,
				// 		});

				// 		console.log(ingestions, "hehe ingestions");
				// 	} else if (item.process_status.toLowerCase() == "pending") {
				// 		let ingestion = {
				// 			name: `${formData?.base_path?.split("/data/")[1]}`,
				// 			use_case: item.use_case,
				// 			process_id: item.process_id,
				// 			status: item.process_status.toLowerCase(),
				// 			timestamp: Date.now(),
				// 		};

				// 		newIngestions.push(ingestion);
				// 		setResponseError(
				// 			`Ingestion for ${getUseCaseName(item.use_case)} is scheduled.`
				// 		);
				// 		setAlertVisible(true);
				// 		setAlertType("info");
				// 	} else {
				// 		if (item.process_status.toLowerCase() == "failure") {
				// 			setResponseError(item.error_message);
				// 			setAlertVisible(true);
				// 			setAlertType("danger");
				// 		}
				// 	}

				// 	setIngestions([...ingestions, ...newIngestions]);
				// 	localStorage.setItem(
				// 		"ingestionData",
				// 		JSON.stringify([...ingestions, ...newIngestions])
				// 	);
				// 	// Show alerts for each ingestion
				// 	newIngestions.forEach((ingestion) => {
				// 		setResponseError(
				// 			`Ingestion for ${getUseCaseName(ingestion.use_case)} is ${
				// 				ingestion.status
				// 			}.`
				// 		);
				// 		setAlertVisible(true);
				// 		setAlertType(ingestion.status === "success" ? "success" : "info");
				// 	});
				// });

				// setIngestions([...ingestions, ...newIngestions]);
				// localStorage.setItem(
				// 	"ingestionData",
				// 	JSON.stringify([...ingestions, ...newIngestions])
				// );

				// setResponseError("Ingestion started successfully...!");
				// setAlertVisible(true);
				// setAlertType("success");
				// setFormData({
				// 	use_case: [],
				// 	base_path: "",
				// 	requires_enrichment: false,
				// });

				// Render alerts in order
				ingestionAlerts.forEach((ingestion, index) => {
					setTimeout(() => {
						setResponseError(
							`Ingestion for ${getUseCaseName(ingestion.use_case)} is ${
								ingestion.status
							}.`
						);
						setAlertVisible(true);
						setAlertType(ingestion.status === "success" ? "success" : "info");
					}, index * 1000); // Adjust timing as needed
				});
			}
		} catch (err) {
			setResponseError("Some errors occured. Please try again later.");
			setAlertVisible(true);
			setAlertType("danger");
			console.log(err);
		}
	};

	const getFilesList = async () => {
		try {
			const axiosInstance = createAxiosInstance({
				"Content-Type": "multipart/form-data",
			});

			const response = await axiosInstance.get("/ingest/list_files");
			setListFiles(response?.data?.files);
			if (response.status == 401 || response.status == 403) {
				logout();
				window.location.href = "/";
				// navigate(-1);
				return;
			}
		} catch (error: any) {
			console.error("Error listing files:", error);

			setResponseError("Error listing files. Try again later.");
			setAlertVisible(true);
			setAlertType("danger");
			if (error?.response.status == 401 || error.response.status == 403) {
				logout();
				window.location.href = "/";
				// navigate(-1);
				return;
			}
		}
	};

	const fetchIngestionData = () => {
		const storedData = localStorage.getItem("ingestionData");
		if (storedData) {
			const parsedData = JSON.parse(storedData);
			const filteredData = parsedData.filter(
				(item: { timestamp: number }) =>
					Date.now() - item.timestamp <= 48 * 60 * 60 * 1000
			);
			setIngestions(filteredData);
		}
	};

	useEffect(() => {
		getFilesList();
		if (!userData?.is_admin || userData?.is_admin == 0) {
			navigate("/");
		}
		fetchIngestionData();
	}, []);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const dropdownElement = document.querySelector(".use-case-dropdown");
			if (dropdownElement && !dropdownElement.contains(event.target as Node)) {
				setIsDropdownOpen(false);
			}
		};

		// Only add the listener when the dropdown is open
		if (isDropdownOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		// Cleanup
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isDropdownOpen]);

	// Use useEffect to handle the timing logic
	useEffect(() => {
		const timers: NodeJS.Timeout[] = [];

		ingestionAlerts.forEach((_, index) => {
			// Set a timeout to remove the alert after 3 seconds
			const timer = setTimeout(() => {
				setIngestionAlerts((prevAlerts) =>
					prevAlerts.filter((_, i) => i !== index)
				);
			}, (index + 1) * 1000 + 3000); // 1 second delay per item + 3 seconds display time

			timers.push(timer);
		});

		// Cleanup timers on component unmount
		return () => {
			timers.forEach(clearTimeout);
		};
	}, [ingestionAlerts]);

	const checkStatus = async (process_id: string) => {
		try {
			const axiosInstance = createAxiosInstance({
				"Content-Type": "multipart/form-data",
			});

			const response = await axiosInstance.get(
				"/ingest/check_process/" + process_id
			);
			// alert(`Status: ${response?.data?.status}`);
			const status = response?.data?.status.toLowerCase();
			if (response?.data?.status == "SUCCESS") {
				// setResponseError(`Status: ${response?.data?.status}`);
				// setAlertVisible(true);
				// setAlertType("success");

				const storedData = localStorage.getItem("ingestionData");
				if (storedData) {
					const parsedData = JSON.parse(storedData);

					const matchedIngestion = parsedData.filter(
						(item: { process_id: string }) => item.process_id == process_id
					)[0];
					let currentIngestionItem = {
						name: `${matchedIngestion?.name}`,
						use_case: matchedIngestion?.use_case,
						process_id: matchedIngestion?.process_id,
						status: status,
						message: "completed successfully.",
						timestamp: Date.now(),
					};

					setIngestionAlerts([...ingestionAlerts, currentIngestionItem]);

					// Filter out the object with the matching process_id
					const updatedData = parsedData.filter(
						(item: { process_id: string }) => item.process_id !== process_id
					);

					// Save the updated array back to local storage
					localStorage.setItem("ingestionData", JSON.stringify(updatedData));

					// Update the state to reflect the changes
					setIngestions(updatedData);
				}
			} else {
				if (response?.data?.status !== "PENDING") {
					// setResponseError(`Status: ${response?.data?.status}`);
					// setAlertVisible(true);
					// setAlertType("danger");

					// let currentIngestionItem = {
					// 	name: `${formData?.base_path?.split("/data/")[1]}`,
					// 	use_case: response?.data?.use_case,
					// 	process_id: response?.data?.process_id,
					// 	status: status,
					// 	timestamp: Date.now(),
					// };

					// setIngestionAlerts([...ingestionAlerts, currentIngestionItem]);
					// status is not success and not pending...! means it failed
					const storedData = localStorage.getItem("ingestionData");
					if (storedData) {
						const parsedData = JSON.parse(storedData);
						const matchedIngestion = parsedData.filter(
							(item: { process_id: string }) => item.process_id == process_id
						)[0];
						let currentIngestionItem = {
							name: `${matchedIngestion?.name}`,
							use_case: matchedIngestion?.use_case,
							process_id: matchedIngestion?.process_id,
							message: "got failed.",
							status: status,
							timestamp: Date.now(),
						};

						setIngestionAlerts([...ingestionAlerts, currentIngestionItem]);
						// Filter out the object with the matching process_id
						const updatedData = parsedData.filter(
							(item: { process_id: string }) => item.process_id !== process_id
						);

						// Save the updated array back to local storage
						localStorage.setItem("ingestionData", JSON.stringify(updatedData));

						// Update the state to reflect the changes
						setIngestions(updatedData);
					}
				} else {
					// status is pending
					const storedData = localStorage.getItem("ingestionData");
					if (storedData) {
						const parsedData = JSON.parse(storedData);
						const matchedIngestion = parsedData.filter(
							(item: { process_id: string }) => item.process_id == process_id
						)[0];

						let currentIngestionItem = {
							name: `${matchedIngestion?.name}`,
							use_case: matchedIngestion?.use_case,
							process_id: matchedIngestion?.process_id,
							message: "is pending.",
							status: status,
							timestamp: Date.now(),
						};
						// Check if the same process_id with the same status already exists in ingestionAlerts
						const isDuplicate = ingestionAlerts.some(
							(alert) =>
								alert.process_id === currentIngestionItem.process_id &&
								alert.status === currentIngestionItem.status
						);
						if (!isDuplicate) {
							setIngestionAlerts([...ingestionAlerts, currentIngestionItem]);
						}
					}
					// setResponseError(`Status: ${response?.data?.status}`);
					// setAlertVisible(true);
					// setAlertType("info");
				}
			}
		} catch (error) {
			console.error("Error confirming ingestion status:", error);
			// alert(`Wrong file or ingestion has failed.`);
			setResponseError("Wrong file or ingestion has failed.");
			setAlertVisible(true);
			setAlertType("danger");
			// remove the ingestion from the list
			const storedData = localStorage.getItem("ingestionData");
			if (storedData) {
				const parsedData = JSON.parse(storedData);

				// Filter out the object with the matching process_id
				const updatedData = parsedData.filter(
					(item: { process_id: string }) => item.process_id !== process_id
				);

				// Save the updated array back to local storage
				localStorage.setItem("ingestionData", JSON.stringify(updatedData));

				// Update the state to reflect the changes
				setIngestions(updatedData);
			}
		}
	};

	return (
		<section className="authentication h-100">
			<div className="row align-items-center justify-content-center min-vh-96 w-100">
				<div className="col-md-10 col-lg-9 col-xl-6">
					<div className="card  mx-auto text-center">
						<div className="card-body">
							<div className="mb-3">
								<LogoIcon />
							</div>
							<h2 className="my-3 my-md-4"> Ingest Uploaded Files </h2>
							{/* <p className="text-secondary">
                Have you already uploaded all new files ?{" "}
                <a href="">Ingest Data</a>
              </p> */}
							<form action="" onSubmit={handleSubmit}>
								<div className="form-floating mb-3 ">
									<select
										className={`form-select ${
											errors.base_path ? "is-invalid" : ""
										}`}
										id="floatingSelect"
										aria-label="Floating label select example"
										name="base_path"
										value={formData.base_path}
										onChange={handleChange}
									>
										<option>Select a file</option>
										{listFiles?.map((file) => {
											return (
												<option value={file}>{file.split("/data/")[1]}</option>
											);
										})}
									</select>
									<label htmlFor="floatingSelect">File</label>
									{errors.base_path && (
										<div className="text-danger small">{errors.base_path}</div>
									)}
								</div>

								<div className="form-floating mb-3">
									<div
										className="form-select use-case-dropdown position-relative"
										onClick={() => setIsDropdownOpen(!isDropdownOpen)}
									>
										{/* <button
											type="button"
											className={` text-start ${
												errors.use_case ? "is-invalid" : ""
											}`}
										>
											{formData.use_case.length > 0
												? `${formData.use_case.length} use cases selected`
												: "Select use cases"}
										</button> */}
										<div className="text-start">
											{formData.use_case.length > 0
												? `${formData.use_case.length} use cases selected`
												: "Select use cases"}
										</div>

										{isDropdownOpen && (
											<div
												className="dropdown-menu show w-100 position-absolute"
												style={{ left: 0, fontSize: "1rem", lineHeight: 2 }}
												onClick={(e) => e.stopPropagation()}
											>
												<div className="dropdown-item" key="use_case_options_0">
													<label className="d-flex align-items-center gap-2">
														Select use cases
													</label>
												</div>
												{useCases.map((useCase: UseCase, key) => (
													<div
														key={`use_case_options_${key}`}
														className="dropdown-item"
														onClick={(e) => e.stopPropagation()}
													>
														<label className="d-flex align-items-center gap-2">
															<input
																type="checkbox"
																checked={formData.use_case.includes(
																	useCase.value.toString()
																)}
																onChange={(e) => {
																	e.stopPropagation();
																	handleUseCaseChange(
																		e,
																		useCase.value.toString()
																	);
																}}
															/>
															{useCase.name}
														</label>
													</div>
												))}
											</div>
										)}
									</div>
									{/* <select
										// multiple
										name="use_case"
										id=""
										className={`form-select ${
											errors.use_case ? "is-invalid" : ""
										}`}
										value={formData.use_case}
										onChange={handleChange}
									>
										<option>Select a use case</option>
										{useCases.map((useCase: UseCase, key) => (
											<option
												value={useCase.value}
												key={`use_case_options_${key}`}
												selected={useCase.value == Number(useCase)}
											>
												{useCase.name}
											</option>
										))}
									</select> */}
									<label htmlFor="floatingSelect">Use Case</label>
									{errors.use_case && (
										<div className="text-danger small">{errors.use_case}</div>
									)}
								</div>
								<div className="form-check  text-start mb-3">
									<input
										className="form-check-input"
										type="checkbox"
										value="1"
										name="agreed"
										id="flexCheckDefault"
									/>
									<label
										className="form-check-label"
										htmlFor="flexCheckDefault"
									>
										Requires Enrichment{" "}
									</label>
								</div>

								<div className="mb-3">
									<button
										type="submit"
										className="btn btn-primary w-100"
										//   onClick={handleUpload}
										disabled={Object.keys(errors).length > 0}
									>
										Submit
									</button>
								</div>
								<BackButton />
							</form>

							<div className="container">
								{ingestions.map((ingestion) => {
									return (
										<div className="row mb-4">
											<div className="col-sm-8 pt-1">
												<b style={{ fontSize: "1.1rem" }}>
													{getUseCaseName(ingestion.use_case)}
												</b>
												{": "}
												{ingestion.name}
											</div>
											<div className="col-sm-4">
												<button
													className="btn btn-primary"
													onClick={() => checkStatus(ingestion?.process_id)}
												>
													check status{" "}
												</button>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				<div className="container mt-5" style={{ position: "relative" }}>
					<div
						className="container mt-5 d-flex flex-column align-items-end"
						style={{
							position: "fixed",
							top: "10px",
							right: "20px",
						}}
					>
						{ingestionAlerts.map((ingestion, index) => (
							<div
								key={index}
								className="alert-container"
								style={{
									marginTop: "1%",
								}}
							>
								<div
									className={`alert alert-item alert-${
										ingestion.status === "success"
											? "success"
											: ingestion.status === "pending"
											? "info"
											: "danger"
									} alert-dismissible fade show`}
									role="alert"
									style={{
										// position: "fixed",
										// top: "100px",
										// right: "20px",
										zIndex: 1051, // Ensures the alert is on top of other content
										minWidth: "20vw", // Optional: ensures a minimum width for the alert
										maxWidth: "max-content",
									}}
								>
									<svg
										className="bi flex-shrink-0 me-2"
										width="24"
										height="24"
										role="img"
										aria-label="Info:"
									>
										<use xlinkHref="#info-fill" />
									</svg>

									<span style={{ fontSize: "1.2rem" }}>
										{" "}
										{`Ingestion for ${getUseCaseName(ingestion.use_case)} ${
											ingestion.message
										}.`}{" "}
									</span>
									<button
										type="button"
										className="btn-close"
										aria-label="Close"
										onClick={() => {
											setIngestionAlerts((prevAlerts) =>
												prevAlerts.filter((_, i) => i !== index)
											);
										}}
									></button>
								</div>
								{/* <Alert
									message={`Ingestion for ${getUseCaseName(
										ingestion.use_case
									)} is ${ingestion.status}.`}
									type={ingestion.status === "success" ? "success" : "info"}
									show={alertVisible}
									onClose={() => setAlertVisible(false)}
								/> */}
							</div>
						))}
					</div>
				</div>
				<Footer />
			</div>

			<ChatHistory
				onNavigate={(path: string) => {
					navigate(path);
				}}
			/>
			<Alert
				message={`${responseError}`}
				type={alertType}
				show={alertVisible}
				onClose={() => setAlertVisible(false)}
			/>
		</section>
	);
};

export default IngestData;
