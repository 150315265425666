import { useState } from "react";
import {
	useReactTable,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	flexRender,
	ColumnFiltersState,
	getFilteredRowModel,
} from "@tanstack/react-table";

interface PDAlertTableProps {
	data: any[];
	columns: any[];
}

const AdminTables = ({ data, columns }: PDAlertTableProps) => {
	const [sorting, setSorting] = useState<SortingState>([]);
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const table = useReactTable({
		data,
		columns: columns,
		state: {
			sorting,
			columnFilters,
		},
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	});

	const handleFilterChange = (columnId: string, value: string) => {
		setColumnFilters((old) => {
			const newFilters = old.filter((filter) => filter.id !== columnId);
			if (value) {
				newFilters.push({ id: columnId, value });
			}
			return newFilters;
		});
	};

	console.log("columnFilters : ", columnFilters);

	return (
		<div style={{ width: "95vw", overflowX: "auto" }}>
			<table className="table table-striped" style={{ width: "100%" }}>
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id} style={{ borderBottomColor: "#0156A8" }}>
							{headerGroup.headers.map((header) => (
								<th
									key={header.id}
									onClick={header.column.getToggleSortingHandler()}
									style={{
										cursor: "pointer",
										width: (header.column.columnDef as any).width,
										fontSize: "14px",
										fontWeight: 700,
										lineHeight: "17.6px",
										color: "#000000",
									}}
								>
									{flexRender(
										header.column.columnDef.header,
										header.getContext()
									)}
									<input
										className="form-control"
										value={String(
											columnFilters.find((filter) => filter.id === header.id)
												?.value || ""
										)}
										onChange={(e) =>
											handleFilterChange(header.id, e.target.value)
										}
										placeholder={`Search ${header.id.split("_").join(" ")}`}
									/>
									{/* {{
										asc: " 🔼",
										desc: " 🔽",
									}[header.column.getIsSorted() as string] ?? null} */}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map((row) => {
						console.log("row : ", row);
						return (
							<tr key={row.id}>
								{row.getVisibleCells().map((cell) => (
									<td
										key={cell.id}
										style={{
											padding: "8px",
											paddingBottom: "28px",
											fontSize: "14px",
											fontWeight: 400,
										}}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default AdminTables;
