import React, { useEffect, useState } from "react";
import { useCases } from "../../../utils/constants";
import moment from "moment";
import { useTypewriter } from "react-simple-typewriter";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import copyIcon from "../../../assets/img/copy.png";

export interface MessageWrapProps {
	msg: any;
	icon?: boolean;
	showMessage: boolean;
	selectUseCase: (value: number) => void;
	setDisabled: (param: boolean) => void;
}

const TypewriterEffect: React.FC<{
	message: any;
	setDisabled: (param: boolean) => void;
}> = ({ message, setDisabled }) => {
	const [isUserScrolling, setIsUserScrolling] = useState(false);
	const [key, setKey] = useState(0);

	const [texting, helper] = useTypewriter({
		words: [message.text],
		loop: 1,
		typeSpeed: 5,
	});

	useEffect(() => {
		setKey((prevKey) => prevKey + 1);
	}, [message.text]);

	useEffect(() => {
		if (helper.isDone && message.text.length > 0) {
			setDisabled(false);
		} else if (helper.isType) {
			setDisabled(true);
		}
	}, [helper]);

	useEffect(() => {
		let userHasScrolled = false;

		const handleUserScroll = () => {
			userHasScrolled = true;
			setIsUserScrolling(true);
			window.removeEventListener("scroll", handleScroll);
		};

		const handleScroll = () => {
			const scrollTop =
				window.pageYOffset || document.documentElement.scrollTop;
			const scrollHeight = document.documentElement.scrollHeight;
			const clientHeight = document.documentElement.clientHeight;

			// Check if user is near the bottom
			if (scrollHeight - scrollTop <= clientHeight + 100) {
				setIsUserScrolling(false);
			} else {
				setIsUserScrolling(true);
			}

			if (!userHasScrolled) {
				userHasScrolled = true;
				window.removeEventListener("scroll", handleScroll);
			}
		};

		window.addEventListener("scroll", handleScroll);
		window.addEventListener("wheel", handleUserScroll);
		window.addEventListener("touchmove", handleUserScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
			window.removeEventListener("wheel", handleUserScroll);
			window.removeEventListener("touchmove", handleUserScroll);
		};
	}, []);

	useEffect(() => {
		const scrollToBottom = () => {
			window.scrollTo(0, document.body.scrollHeight);
		};
		if (!isUserScrolling) {
			scrollToBottom();
		}
	}, [texting, isUserScrolling]);

	return (
		// <ReactMarkdown children={message?.typewriter ? texting : message.text} />
		<div key={key}>
			<ReactMarkdown
				remarkPlugins={[remarkBreaks]}
				children={texting.replace(/\t/gi, "&nbsp; &nbsp; &nbsp;")}
				components={{
					li: ({ node, ...props }) => (
						<li style={{ marginBottom: "3%" }} {...props} />
					),
					p: ({ node, ...props }) => (
						<p className="card-text nignt" {...props} />
					),
				}}
			/>
		</div>
	);
};
const MessageWrap: React.FC<MessageWrapProps> = React.memo(
	({ msg, showMessage, selectUseCase, setDisabled }) => {
		const listStyles = {
			marginBottom: "5%",
		};

		// Add state for tracking copy status
		const [isCopied, setIsCopied] = useState(false);
		// State to track the number of words to display
		// const [wordsToShow, setWordsToShow] = useState(20);

		// const containsSpecialCharacters = (text: string) => /[\n\t]/.test(text);
		const formattedTime = moment(msg.time, "h:mm:ss A").format("h:mm A");
		useEffect(() => {
			if (msg.text.length == 0) {
				setDisabled(false);
			}
		}, [msg.text]);

		// Add this handler
		const handleCopy = async (text: string) => {
			try {
				// Clean the text before copying
				const cleanText = text
					.replace(/&nbsp;/g, " ") // Replace &nbsp; with regular space
					.replace(/\\n/g, "\n") // Replace literal \n with newline
					.replace(/\\t/g, "    ") // Replace literal \t with spaces
					.replace(/\\r/g, "") // Remove \r
					// .replace(/\s+/g, " ") // Normalize multiple spaces
					.trim();
				await navigator.clipboard.writeText(cleanText);
				setIsCopied(true);
				// toast.success('Copied to clipboard!');
			} catch (err) {
				// toast.error('Failed to copy text');
				console.error("Failed to copy text: ", err);
			} finally {
				// Reset the icon after 2 seconds
				setTimeout(() => {
					setIsCopied(false);
				}, 2000);
			}
		};

		// // Function to toggle the number of words displayed
		// const toggleWords = () => {
		// 	setWordsToShow((prev) => prev + 200);
		// };

		// // Function to get the truncated message
		// const getTruncatedMessage = (text: string, wordLimit: number) => {
		// 	const words = text.match(/\S+/g) || [];
		// 	if (words.length <= wordLimit) return text;
		// 	return words.slice(0, wordLimit).join(" ") + "...";
		// };

		// // Determine if "see more" should be shown
		// const showSeeMore = (msg.text.match(/\S+/g) || []).length > wordsToShow;

		// State to track the number of headings to display
		const [headingsToShow, setHeadingsToShow] = useState(5);

		// Function to toggle the number of headings displayed
		const toggleHeadings = () => {
			setHeadingsToShow(totalHeadings);
		};
		// Function to get the truncated message based on headings
		const getTruncatedMessageByHeadings = (
			text: string,
			headingLimit: number
		) => {
			// Regular expression to match Markdown headings
			const headingRegex = /^(\*+)\s+(.*)$/gm;
			let match;
			let count = 0;
			let lastIndex = 0;

			while ((match = headingRegex.exec(text)) !== null) {
				count++;
				if (count > headingLimit) {
					break;
				}
				lastIndex = match.index;
			}

			return count > headingLimit ? text.slice(0, lastIndex) : text;
		};

		// Determine if "see more" should be shown
		const totalHeadings = (msg.text.match(/^(\*+)\s+/gm) || []).length;
		const showSeeMore = totalHeadings > headingsToShow;

		return (
			<div className={`message-wrap ${msg.type}`}>
				<div className="d-flex flex-column">
					<div className="card">
						<div className="card-body">
							<div className="d-flex msg-user-info">
								{/* {containsSpecialCharacters(msg.text) ? ( */}
								{msg?.typewriter ? (
									<div className="card-text nignt" key={msg.time}>
										{/* {msg.text.length > 0 && (
											<i className="bi bi-chat-dots pe-2"></i>
											)} */}
										{/* {msg?.typewriter ? ( */}
										{/* <TypewriterEffect message={msg} setDisabled={setDisabled} /> */}
										<ReactMarkdown
											remarkPlugins={[remarkBreaks]}
											// children={
											// 	(msg.text = msg.text.replace(
											// 		/\t/gi,
											// 		"&nbsp; &nbsp; &nbsp;"
											// 	))
											// }
											// children={getTruncatedMessage(msg.text, wordsToShow)}
											children={getTruncatedMessageByHeadings(
												msg.text,
												headingsToShow
											)}
											components={{
												li: ({ node, ...props }) => (
													<li style={{ marginBottom: "3%" }} {...props} />
												),
												p: ({ node, ...props }) => (
													<p className="card-text nignt" {...props} />
												),
											}}
										/>

										{showSeeMore && (
											<a
												href="#"
												onClick={(e) => {
													e.preventDefault();
													// toggleWords();
													toggleHeadings();
												}}
												style={{ color: "#007bff", cursor: "pointer" }}
											>
												See more
											</a>
										)}
										{/* ) : (
										<ReactMarkdown children={msg.text} />
										)} */}
									</div>
								) : (
									<p className="card-text nignt">
										{msg.icon ||
											(msg.text.length == 0 && (
												<i className="bi bi-chat-dots pe-2 blinking-icon"></i>
											))}

										<ReactMarkdown
											remarkPlugins={[remarkBreaks]}
											// children={
											// 	(msg.text = msg.text.replace(
											// 		/\t/gi,
											// 		"&nbsp; &nbsp; &nbsp;"
											// 	))
											// }
											// children={getTruncatedMessage(msg.text, wordsToShow)}
											children={getTruncatedMessageByHeadings(
												msg.text,
												headingsToShow
											)}
											components={{
												li: ({ node, ...props }) => (
													<li style={{ marginBottom: "3%" }} {...props} />
												),
												p: ({ node, ...props }) => (
													<p className="card-text nignt" {...props} />
												),
											}}
										/>
										{/* {msg.text &&
											msg.type === "left" && ( // Only show copy icon if there's text
												<i
													className="bi bi-clipboard position-absolute top-0 end-0 p-2 cursor-pointer"
													onClick={() => handleCopy(msg.text)}
													style={{ cursor: "pointer" }}
												/>
											)} */}

										{showSeeMore && (
											<a
												href="#"
												onClick={(e) => {
													e.preventDefault();
													// toggleWords();
													toggleHeadings();
												}}
												style={{ color: "#007bff", cursor: "pointer" }}
											>
												See more
											</a>
										)}
										{showMessage && msg.type == "left" && (
											<p className="card-text nignt">
												<a
													href="#"
													onClick={() => {
														selectUseCase(useCases[0].value);
													}}
												>
													{useCases[0].name}
												</a>
												<br />
												<a
													href="#"
													onClick={() => {
														selectUseCase(useCases[1].value);
													}}
												>
													{useCases[1].name}
												</a>

												<br />
												<a
													href="#"
													onClick={() => {
														selectUseCase(useCases[2].value);
													}}
												>
													{useCases[2].name}
												</a>
												<br />
												<a
													href="#"
													onClick={() => {
														selectUseCase(useCases[3].value);
													}}
												>
													{useCases[3].name}
												</a>
											</p>
										)}
									</p>
								)}
							</div>
						</div>
					</div>
					{msg.text &&
						msg.type === "left" && // Only show copy icon if there's text
						(!isCopied ? (
							<img
								src={copyIcon}
								alt={"Copy"}
								onClick={() => handleCopy(msg.text)}
								style={{
									cursor: "pointer",
									paddingTop: "10px",
									transition: "all 0.2s ease",
									width: "23px", // Adjust size as needed
									height: "27px", // Adjust size as needed
									opacity: isCopied ? 0.7 : 1,
								}}
							/>
						) : (
							<i
								className={`bi bi-check2 bottom-0 p-2 cursor-pointer`}
								onClick={() => handleCopy(msg.text)}
								style={{
									cursor: "pointer",
									fontSize: "18px",
									fontWeight: 500,
									paddingTop: "10px",
									transition: "all 0.2s ease",
									color: "#9eccff",
								}}
							/>
						))}
					<small className="text-muted datetime">{formattedTime}</small>
				</div>
			</div>
		);
	}
);

export default MessageWrap;
