import React, { useEffect, useRef, useState } from "react";
import "../../../assets/scss/spinStyles.css";
import { useChatHistory } from "../../../context/chatHistoryContext";
import { useAuth } from "../../../context/authContext";
import Alert from "../alerts/alert";
import { useChat } from "../../../context/chatContext";
import createAxiosInstance from "../../../utils/axiosConfig";
import { start_chat } from "../../../utils/endpoints";
interface ChatFooterProps {
	addMessage: (text: string, type: string) => void;
	chat_id: string;
	getResponse: (label: string, chat_id: string, type: string) => void;
	disabled: boolean;
	showNistCards?: boolean;
	showCards: boolean;
	// setDisabled: any;
	// getHistory: () => void;
}

const ChatFooter: React.FC<ChatFooterProps> = React.memo(
	({
		chat_id,
		addMessage,
		getResponse,
		disabled,
		showNistCards = false,
		showCards,
	}) => {
		const { isAuthenticated } = useAuth();
		const { openChatHistory } = useChatHistory();
		const { selectedUseCase, selectedSubCase, startChat } = useChat();
		const [message, setMessage] = useState("");
		const [alertVisible, setAlertVisible] = useState(false);

		const inputRef = useRef<HTMLInputElement>(null);

		// const [showCards1, setShowCards] = useState(showCards);
		const handleBackClick = () => {
			console.log("set value for handle back");
			window.location.href = "/";
		};
		const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			setMessage(e.target.value);
		};

		const handleSend = () => {
			// disable if use case is vendor selection and user has not yet elected a subcase...!
			if (selectedUseCase == 3 && !selectedSubCase) {
				addMessage(
					"Please Choose your path before proceeding further.",
					"left"
				);
				setMessage("");
				return;
			}

			if (message.trim()) {
				addMessage(message, "right");
				setMessage("");
				getResponse(message, chat_id, "left");
			}
		};

		const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === "Enter" && message.length > 0 && !disabled) {
				handleSend();
			}
		};

		const handleAllConversationClick = (
			e: React.MouseEvent<HTMLAnchorElement>
		) => {
			e.preventDefault();
			if (isAuthenticated) {
				openChatHistory();
			} else {
				setAlertVisible(true);
			}
		};

		useEffect(() => {
			if (chat_id !== "" && chat_id.length > 0) {
				if (selectedUseCase === 3 && !selectedSubCase) {
					return;
				} else {
					inputRef?.current?.focus();
				}
			}
		}, [chat_id, selectedSubCase]);

		return (
			<section className="chatfooter mt-auto">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-10">
							{!showCards && (
								<div className="text-center" style={{ marginBottom: "2%" }}>
									<div className="btn btn-link" onClick={handleBackClick}>
										<i className="bi bi-arrow-left me-2"></i>Back
									</div>
								</div>
							)}

							{!showNistCards && (
								<div className="d-flex gap-3 mb-3">
									{!showCards && (
										<button
											className="btn btn-secondary iconround iconmedium"
											style={{ background: "#205493 !important" }}
											onClick={() => startChat(selectedUseCase)}
										>
											<i className="bi bi-arrow-repeat"></i>
										</button>
									)}
									<input
										ref={inputRef}
										type="text"
										className="form-control chatinput"
										placeholder="Type your message"
										value={message}
										onChange={handleInputChange}
										onKeyPress={handleKeyPress}
									/>
									<button
										className="btn btn-secondary iconround iconmedium"
										style={{ background: "#205493 !important" }}
										onClick={() => handleSend()}
										disabled={disabled || message.length === 0}
									>
										<i
											className={`${
												disabled
													? "bi bi-arrow-clockwise icon-spin"
													: "bi bi-send"
											}`}
										></i>
									</button>
								</div>
							)}
							<div className="row">
								<div className="col-4">
									<span>
										<a
											className={`small ${!isAuthenticated ? "disabled" : ""}`}
											// data-bs-toggle="offcanvas"
											// href="#offcanvasExample"
											role="button"
											// aria-controls="offcanvasExample"
											onClick={handleAllConversationClick}
										>
											<i className="bi bi-chat-right-dots"></i> All Conversation
										</a>
									</span>
								</div>
								<div className="col-4">
									<span style={{}}>
										<a className="small" href="/terms-and-conditions">
											Terms and Conditions
										</a>
									</span>
								</div>
								<div className="col-4" style={{ textAlign: "center" }}>
									<span style={{}}>
										<a className="small" href="/privacy-policy">
											Privacy Policy
										</a>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Alert
					message="Loads only when logged in."
					type="warning"
					show={alertVisible}
					onClose={() => setAlertVisible(false)}
				/>
			</section>
		);
	}
);

export default ChatFooter;
