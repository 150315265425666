import React, { useEffect } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useLocation,
} from "react-router-dom";
import privateRoutes from "./routes/privateRoutes";
import publicRoutes from "./routes/publicRoutes";
import "./assets/scss/app.css";
import { useAuth } from "./context/authContext";

const App: React.FC = () => {
	const { isAuthenticated, checkAuthStatus } = useAuth();
	return (
		<>
			<Router>
				<LocationListener checkAuthStatus={checkAuthStatus} />
				<Routes>
					{!isAuthenticated &&
						publicRoutes.map((route, index) => (
							<Route key={index} path={route.path} element={route.element} />
						))}
					<Route
						path="/*"
						element={
							isAuthenticated ? (
								<Routes>
									{privateRoutes.map((route, index) => (
										<Route
											key={index}
											path={route.path}
											element={route.element}
										/>
									))}
									{/* Redirect any random route to home */}
									{/* <Route path="*" element={<Navigate to="/" replace />} /> */}
								</Routes>
							) : (
								<Navigate to="/login" replace />
							)
						}
					/>
				</Routes>
			</Router>
		</>
	);
};

const LocationListener: React.FC<{ checkAuthStatus: () => void }> = ({
	checkAuthStatus,
}) => {
	const location = useLocation();

	useEffect(() => {
		checkAuthStatus();
	}, [checkAuthStatus, location]);

	return null;
};

export default App;
