import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../footer";
import BackButton from "../backButton";
import ChatHistory from "../chatHistory";

const Pricing = () => {
	// const [price, setPrice] = useState(238.8);
	const [accountType, setAccountType] = useState<number>(0);
	const navigate = useNavigate();
	// Below is commented out because we only want to go with yearly prices

	// const calculateYearlyPrice = () => {
	//   if (price <= 19.9) {
	//     setPrice(roundToDecimal(price * 12, 2));
	//   }
	// };
	// const calculateMonthlyPrice = () => {
	//   if (price > 19.9) {
	//     setPrice(roundToDecimal(price / 12, 2));
	//   } else {
	//     setPrice(19.9);
	//   }
	// };
	// const roundToDecimal = (value: number, decimals: number) => {
	//   const factor = Math.pow(10, decimals);
	//   return Math.round(value * factor) / factor;
	// };

	useEffect(() => {
		const storedUserData = localStorage.getItem("userData");
		const userData = JSON.parse(storedUserData || "{}");

		if (userData?.account_type) {
			setAccountType(userData?.account_type);
		}
	});

	return (
		<section className="homemain ">
			<div className="container">
				<div className="row justify-content-center mt-5 pt-5">
					<div className="col-md-9">
						<article className=" text-center mb-md-5 mb-3 ">
							<h1 className="mb-md-3 mb-2 text-blue">
								{accountType == 0 ? "Choose Your Plan" : "BENEFITS"}
							</h1>
							<p className="text-secondary">
								Benefit from collective wisdom and diverse perspectives of our
								contributors with
								<br />
								<b>
									{" "}
									<u>hundreds</u>
								</b>{" "}
								of years of cyber experience leading to more informed decisions,
								innovative <br /> solutions, and a deeper understanding of the
								complex cyber security challenges you <br />
								face.
							</p>
							{/* <p className="text-secondary">Simple Pricing. No Hidden Fees</p> */}
							<div
								className="togglebtn"
								role="group"
								aria-label="Basic radio toggle button group"
							></div>
						</article>
						<div className="row mb-md-5 mb-3">
							{accountType == 0 && (
								<div className="col-lg-6 ">
									<div className="card bg-light">
										<div className="card-body p-md-5 p-3">
											<h6>Free</h6>
											<div className="d-flex align-items-center mb-3">
												<h1 className="m-0">$0.0</h1>
												<small className="text-muted ms-2">USD Per Year</small>
											</div>
											<ul className="pricelist">
												<li>
													<i className="bi bi-check"></i>{" "}
													<span>10 Queries in a day</span>
												</li>
												<li>
													<i className="bi bi-check"></i>{" "}
													<span>Limited access to our GPT</span>
												</li>
												<li>
													<i className="bi bi-check"></i>{" "}
													<span>Limited Access to pro features</span>
												</li>
												{/* <li>
                          <i className="bi bi-check"></i>{" "}
                          <span>Limited access to advanced data analysis</span>
                        </li> */}
												{/* <li>
                          <i className="bi bi-check"></i>{" "}
                          <span>Limited access to File Uploads</span>
                        </li> */}
											</ul>
											<button
												className="btn btn-secondary w-100 text-center"
												disabled
											>
												Your Current Plan
											</button>
										</div>
									</div>
								</div>
							)}

							<div className="col-lg-6 " style={{ margin: "auto" }}>
								<div className="card lightblueBg">
									<div className="card-body p-md-5 p-3">
										<h6 className="mb-3">ENTERPRISE Subscription</h6>
										{/* <div className="d-flex align-items-center mb-3">
                      <h1 className="m-0">$18K</h1>
                      <small className="text-muted ms-2"> USD Per Year</small>
                    </div> */}
										<ul className="pricelist">
											<li>
												<i className="bi bi-check"></i>{" "}
												<span>Frequent Content updates</span>
											</li>
											<li>
												<i className="bi bi-check"></i>{" "}
												<span>Unlimited Access to Knowledge</span>
											</li>
											<li>
												<i className="bi bi-check"></i>{" "}
												<span>
													Best Practices in Cyber Security & Risk Management
												</span>
											</li>
											<li>
												<i className="bi bi-check"></i>{" "}
												<span>Framework Maturity Assessment</span>
											</li>
											<li>
												<i className="bi bi-check"></i>{" "}
												<span>Vendor Selection and Validation</span>
											</li>
											<li>
												<i className="bi bi-check"></i>{" "}
												<span>Cyber Governance and Risk</span>
											</li>
											<li>
												<i className="bi bi-check"></i>{" "}
												<span>
													Budget Matcher and Product Pricing Recommendations
													<span className="text-secondary">
														{" "}
														(Coming Soon){" "}
													</span>
												</span>
											</li>

											{/* <li>
                        <i className="bi bi-check"></i>{" "}
                        <span>Unlimited access to advanced data analysis</span>
                      </li> */}
											{/* <li>
                        <i className="bi bi-check"></i>{" "}
                        <span>Unlimited access to File Uploads</span>
                      </li> */}
										</ul>
										<button
											className="btn btn-secondary w-100 text-center"
											onClick={() => {
												navigate("/contactSales");
											}}
										>
											Contact Sales
										</button>

										{accountType > 0 && (
											<div className="text-center">
												<BackButton />{" "}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						{accountType === 0 && (
							<div className="text-center">
								<BackButton />{" "}
							</div>
						)}
					</div>
				</div>
			</div>
			<Footer />
			<ChatHistory
				onNavigate={() => {
					navigate("/");
				}}
			/>
		</section>
	);
};

export default Pricing;
