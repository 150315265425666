export const start_chat = `/chat/start_chat`;
export const chat = `/chat/chat`;
export const register = `/register`;
export const login_user = `/login`;
export const add_feedback = `/feedback`;
export const change_password = `/reset-password`;
export const ingest_file = `/ingest/ingest_data`;
export const delete_file = `/ingest/delete/`;
export const ingested_files = `/ingest/ingested_files/`;
export const list_files = `/ingest/list_files`;
export const nist_frameworks = `/categories`;
export const save_score = `/scores`;
export const ingest_file_multiple = `/ingest/multiple_use_case_ingest_data/`;
export const score_history_domain = (user_id: number, domain_id: number) =>
	`/history/user/${user_id}/domain/${domain_id}`;

export const score_history_category = (user_id: number, category_id: number) =>
	`/history/user/${user_id}/category/${category_id}`;
export const user_flow_scores = (user_id: number) => `/scores/user/${user_id}`;
export const framework_domains = (framework_id: number) =>
	`/categories/${framework_id}/domains`;
export const question_flows = (domain_id: number) =>
	`/question-flows/${domain_id}`;
export const get_questions_by_flow_id = (flow_id: number) =>
	`/question_flows/${flow_id}/questions`;

export const category_score = (user_id: number) =>
	`/scores/user/${user_id}/category`;

export const get_form_data = `/update/get_data`;
export const add_vendor_data = `/update/vendordata`;
export const add_governance_data = `/update/boarddata`;

export const export_data = (user_id:number) =>
	`/download_excel/${user_id}`;

