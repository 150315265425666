import React, { useEffect, useState } from "react";
import {
	FlowScore,
	FrameworkDomain,
	QuestionFlow,
} from "../../../utils/interfaces";
import Card from "./cards";
import createAxiosInstance from "../../../utils/axiosConfig";
import { useAuth } from "../../../context/authContext";
import SideScreenLLM from "./sideScreen";
import ScoreHistory from "./sideScreen/scoreHistory";
import { question_flows, score_history_domain, export_data } from "../../../utils/endpoints";
import { useNavigate } from "react-router-dom";
import FileSaver from 'file-saver';

const Domains = ({
	domains,
	handleQuit,
	frameworkId,
}: {
	domains: FrameworkDomain[];
	handleQuit: () => void;
	frameworkId?: number;
}) => {
	const [selectedDomain, setSelectedDomain] = useState<FrameworkDomain | null>(
		null
	);
	// const navigate = useNavigate();
	const { logout, userId, userData } = useAuth();
	const navigate = useNavigate();
	const [domainScores, setDomainScores] = useState<any[]>([]);

	const [isSideScreenVisible, setIsSideScreenVisible] =
		useState<boolean>(false);
	const [refresh, setRefresh] = useState<number>(0);

	const getLatestScores = (
		scores: FlowScore[],
		questionFlows: QuestionFlow[]
	) => {
		const latestScoresMap = new Map<number, FlowScore>();

		// Filter scores to only include those with question_flow_id matching any id in questionFlows
		const validScores = scores.filter((score) =>
			questionFlows.some((flow) => flow.id === score.question_flow_id)
		);

		validScores.map((score) => {
			const existingScore = latestScoresMap.get(score.question_flow_id);
			if (
				!existingScore ||
				new Date(score.score_date) > new Date(existingScore.score_date)
			) {
				latestScoresMap.set(score.question_flow_id, score);
			}
		});

		return Array.from(latestScoresMap.values());
	};

	const calculateAverageScore = (scores: any[]) => {
		const totalScore = scores.reduce(
			(sum, score) => sum + score?.total_score,
			0
		);
		return totalScore / scores.length;
	};

	const calculateDomainAverageScore = () => {
		const validScores = domainScores.filter((score) =>
			domains.some((domain) => domain.id === score.domain_id)
		);
		return calculateAverageScore(validScores);
	};

	useEffect(() => {
		setRefresh(refresh + 1);
	}, [selectedDomain]);

	useEffect(() => {
		const getDomainScore = async () => {
			try {
				const response = await createAxiosInstance().get(
					`scores/user/${userId}/domain`
				);

				if (response.status == 401 || response.status == 403) {
					// 401 is for expired token
					// 403 is for forbidden access (blocked by GPT.)
					console.log("use token expired");

					// logout();
					// window.location.href = "/";
					// navigate("/");
					return;
				}

				setDomainScores(response.data);
			} catch (e: any) {
				console.log(e, "Error");
			}
		};

		getDomainScore();
	}, [refresh]);

	const handleAIButton = (domain: FrameworkDomain) => {
		setIsSideScreenVisible(!isSideScreenVisible);
		setIsOpen(true);
		setLoading(true);
		// setLLMContent("");
		fetchLLMResponse(domain.id);
		setCurrentLLMDomain(domain);
	};
	const handleExportData = async() => {
		if (!userId) window.location.href = "/";
		try {
			const scoreHistory = await createAxiosInstance().get(
				// download_excel(userId || 0, domainId)
				export_data(userId||0), {
					responseType: "blob", 
				  }
			);

			if (scoreHistory.status == 401 || scoreHistory.status == 403) {
				console.log("user token expired");
				return;
			}
			
			const contentDisposition = scoreHistory.headers["content-disposition"];
			let filename = "downloaded_file.xlsx"; // Default filename

			if (contentDisposition) {
				const match = contentDisposition.match(/filename="?(.+?)"?$/);
				// console.log("Match ", match)
				if (match && match[1]) {
				filename = match[1];
				}
			}
			console.log("File name ", filename)
			const blob = new Blob([scoreHistory.data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			  });

			FileSaver.saveAs(blob, filename.replace(/["']/g, ''));
			  // Create a download link and trigger it
			//   const url = window.URL.createObjectURL(blob);
			//   const a = document.createElement("a");
			//   a.href = url;
			//   a.download = filename // File name
			//   document.body.appendChild(a);
			//   a.click();
			//   document.body.removeChild(a);
			//   window.URL.revokeObjectURL(url); // Clean up

		} catch (e) {
			console.log(e);
		} finally {
			console.log("Finally Executed");
		}
		
	};
	const [isOpen, setIsOpen] = useState(false);
	const [isHistoryOpen, setIsHistoryOpen] = useState(false);
	const [historyLoading, setHistoryLoading] = useState<boolean>(false);
	const [historyContent, setHistoryContent] = useState<{
		last_month: any[];
		last_week: any[];
		last_quarter: any[];
	}>({
		last_month: [],
		last_week: [],
		last_quarter: [],
	});

	const [eventSource, setEventSource] =
		useState<ReadableStreamDefaultReader | null>(null);

	const closeChatHistory = () => {
		setIsOpen(false);
		setCurrentLLMDomain(null);
	};

	const closeScoreHistory = () => {
		setIsHistoryOpen(false);
		setHistoryContent({
			last_month: [],
			last_week: [],
			last_quarter: [],
		});
	};

	const openScoreHistory = (domainId: number) => {
		setIsHistoryOpen(true);
		fetchScoreHistory(domainId);
	};

	const fetchScoreHistory = async (domainId: number) => {
		if (!userId) window.location.href = "/";
		setHistoryLoading(true);
		try {
			const scoreHistory = await createAxiosInstance().get(
				score_history_domain(userId || 0, domainId)
			);

			if (scoreHistory.status == 401 || scoreHistory.status == 403) {
				// 401 is for expired token
				// 403 is for forbidden access (blocked by GPT.)
				console.log("user token expired");
				// logout();
				// window.location.href = "/";
				// navigate("/");
				return;
			}

			setHistoryContent({
				last_week: scoreHistory?.data.last_week.reverse(),
				last_month: scoreHistory?.data.last_month.reverse(),
				last_quarter: scoreHistory?.data.last_quarter.reverse(),
			});
		} catch (e) {
			console.log(e);
		} finally {
			setHistoryLoading(false);
		}
	};

	const [LLMContent, setLLMContent] = useState<any>("");
	const [currentLLMDomain, setCurrentLLMDomain] =
		useState<FrameworkDomain | null>(null);
	const [currentLLMIndex, setCurrentLLMIndex] = useState<number>(0);
	const [payloads, setPayloads] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const handleNext = () => {
		if (currentLLMIndex <= payloads.length - 1) {
			setCurrentLLMIndex(currentLLMIndex + 1);
			setLoading(true);
			if (currentLLMDomain) fetchLLMResponse(currentLLMDomain?.id);
		}
	};

	const handlePrev = () => {
		if (currentLLMIndex !== 0) {
			setCurrentLLMIndex(currentLLMIndex > 0 ? currentLLMIndex - 1 : 0);
			setLoading(true);

			if (currentLLMDomain) fetchLLMResponse(currentLLMDomain?.id);
		}
	};

	const fetchDomainBasedUserAnswers = async (
		user_id: number,
		domain_id: number
	) => {
		try {
			const response = await createAxiosInstance().get(
				`/user_answers/${user_id}/domain/${domain_id}`
			);

			console.log(response);
			return response.data;
		} catch (e: any) {
			console.log(e, "error");
		}
	};

	const fetchQuestionsFlow = async (domainId: number) => {
		if (!domainId) return;

		try {
			const questionsFlowResponse = await createAxiosInstance().get(
				question_flows(domainId)
			);

			if (
				questionsFlowResponse.status == 401 ||
				questionsFlowResponse.status == 403
			) {
				// 401 is for expired token
				// 403 is for forbidden access (blocked by GPT.)
				console.log("user token expired");
				logout();
				navigate("/");
				return;
			}
			return questionsFlowResponse.data;
		} catch (e: any) {
			console.log(e);
		}
	};

	const fetchLLMResponse = async (domain_id: number) => {
		// if (!isOpen) return;
		setLoading(true);
		try {
			// fetch all useranswers for a given domain sorted by Date...
			// pick ones with latest date...
			// prepare the DS...
			// send request to /assessment endpoint.
			// show response in markdown Structure (set LLM content state.).
			if (userId) {
				// Close previous event source if it exists
				if (eventSource) {
					eventSource.cancel();
				}
				const token = localStorage.getItem("token");

				const userAnswers = await fetchDomainBasedUserAnswers(
					userId,
					domain_id
				);
				if (userAnswers.status == 401 || userAnswers.status == 403) {
					// 401 is for expired token
					// 403 is for forbidden access (blocked by GPT.)
					console.log("user token expired");

					logout();
					navigate("/");
					return;
				}

				setPayloads(userAnswers);
				const questionsFlow = await fetchQuestionsFlow(domain_id);

				const payload = {
					...userAnswers,
				};

				// Send the payload to the /assessment endpoint
				const assessmentResponse = await fetch(
					process.env.REACT_APP_SERVER_URL + "/assessment",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({
							...payload[currentLLMIndex],
							question_flow_id: questionsFlow[currentLLMIndex].id,
						}),
					}
				);

				// Set the LLM content state with the response
				// setLLMContent(assessmentResponse.data);
				if (assessmentResponse?.body) {
					const reader = assessmentResponse.body.getReader();
					setEventSource(reader);
				}
			}
		} catch (e: any) {
			console.log(e, "error ");
			// More specific error handling logic here
			if (e instanceof TypeError) {
				// Handle network errors (e.g., network down, CORS issues)
				console.error("Network error:", e);
				return;
			} else {
				// Handle other types of errors
				console.error("Unexpected error:", e);
				return;
			}
		}
	};

	const addToLLMContent = (newContent: string) => {
		setLLMContent((prevContent: any) => `${prevContent}${newContent}`);
	};

	useEffect(() => {
		if (eventSource) {
			const readStream = async () => {
				setLLMContent("");
				// setLoading(false);
				try {
					while (true) {
						const { done, value } = await eventSource.read();
						if (done) {
							break;
						}

						const buffer = new TextDecoder().decode(value, { stream: true });
						// Split buffer by newline characters
						const lines = buffer.split("\n");
						// buffer = lines.pop() || ""; // Keep the last partial line in buffer
						// Process complete lines
						lines.forEach((line) => {
							if (line.startsWith("data: ")) {
								const dataString = line.substring(6); // Remove 'data: ' prefix
								try {
									const data = JSON.parse(dataString);
									if (data.message) {
										// addToLastMessage(data.message, "left");
										addToLLMContent(data.message);
										if (LLMContent.length > 0) {
											setLoading(false);
										}
									}
								} catch (e) {
									console.error("Error parsing JSON:", e);
									// setErrorCount(errorCount + 1);
									return;
								}
							}
						});
					}
				} catch (e) {
					console.error("Error reading stream:", e);
					setEventSource(null);
					setLoading(false);
				} finally {
					setEventSource(null);
					setLoading(false);
				}
			};
			readStream();
		}
	}, [eventSource]);

	return (
		<div className="homemain d-flex justify-content-center align-items-center min-vh-95">
			<div className="container ">
				<div className="row justify-content-center">
					<div className="col-md-10 mb-1">
						<div className="mb-4" style={{ marginBottom: "100%" }}>
							<div className="text-center mb-4">
								<h1 className="mb-md-2 text-center text-blue">
									Current Maturity Score is{" "}
									<span className="">
										{calculateDomainAverageScore().toFixed(2)}
									</span>
								</h1>

								<div className="d-flex flex-column flex-grow-1">
									<p className="card-text text-secondary">
										Get latest score by completing the individual assessment
									</p>
								</div>
							</div>
							<div
								className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4"
								style={{ marginBottom: "3%" }}
							>
								{domains.map((domain: FrameworkDomain, index: number) => {
									const domainScore = domainScores.find(
										(score) =>
											score.domain_id === domain.id && score.total_score > 0
									);
									return (
										<div className="d-flex flex-column">
											<Card
												title={domain.title}
												enabled={
													domain.is_enabled &&
													((userData?.account_type !== undefined &&
														userData?.account_type > 0) ||
														domain.is_free)
												}
												// callbackFn={() => handleDomainSelect(domain)}
												callbackFn={() =>
													navigate(
														`/maturityAssessment/${frameworkId}/areas/${domain.id}/questionflow`
													)
												}
												key={`domain_cards_${index}`}
												scoreObject={domainScore}
												showAIButton={true}
												enableAIButton={domainScore}
												showHistoryButton={domainScore?.total_score > 0}
												handleAIButtonClick={() => handleAIButton(domain)}
												handleHistoryButtonClick={() =>
													openScoreHistory(domain.id)
												}
											/>
										</div>
									);
								})}
							</div>
							<div className="text-center" style={{ marginBottom: "2%" }}>
							<button
										className="btn btn-sm btn-primary mt-2"
										style={{
											visibility: "visible",
										}}
										onClick={handleExportData}
									>
										Export Report
							</button>
							</div>
							<div className="text-center" style={{ marginBottom: "2%" }}>
								<div className="btn btn-link" onClick={handleQuit}>
									<i className="bi bi-arrow-left me-2"></i>Back
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Side screen for AI conversation history */}

			<SideScreenLLM
				isOpen={isOpen}
				handleClose={closeChatHistory}
				LLMContent={LLMContent}
				loading={loading}
				handleNext={handleNext}
				handlePrev={handlePrev}
				showPagination={payloads.length > 1}
			/>
			<ScoreHistory
				isOpen={isHistoryOpen}
				handleClose={closeScoreHistory}
				loading={historyLoading}
				content={historyContent}
			/>
		</div>
	);
	// ) : (
	// <QuestionsFlow
	// 	selectedDomain={selectedDomain}
	// 	questionFlows={questionFlows}
	// 	flowScores={flowScores}
	// 	latestScores={latestScores}
	// 	handleGoBack={handleGoBack}
	// 	handleRefresh={handleRefresh}
	// />
	// );
};

export default Domains;
