import { useNavigate } from "react-router-dom";
import BackButton from "../common/backButton";
import ChatHistory from "../common/chatHistory";
import Footer from "../common/footer";
import ContactForm from "../common/forms/contact";

const Feedback = () => {
	const navigate = useNavigate();
	return (
		<section className="homemain ">
			<div className="container">
				<div className="row justify-content-center mt-5 pt-5">
					<div className="col-md-9">
						<article className=" text-center mb-md-5 mb-3 ">
							<h1 className="mb-md-3 mb-2 text-blue">Write a review</h1>
							<ul className="list-inline">
								<li className="list-inline-item">
									<i className="bi bi-star-fill text-warning fs-5"></i>
								</li>
								<li className="list-inline-item">
									<i className="bi bi-star-fill text-warning fs-5"></i>
								</li>
								<li className="list-inline-item">
									<i className="bi bi-star-fill text-warning fs-5"></i>
								</li>
								<li className="list-inline-item">
									<i className="bi bi-star-fill text-warning fs-5"></i>
								</li>
								<li className="list-inline-item">
									<i className="bi bi-star-fill text-warning fs-5"></i>
								</li>
							</ul>
							<p className="text-secondary">
								Use the form below to send us your comments. We read all
								feedback carefully, but we are unable to respond to each
								submission individually. If you provide your email address, you
								agree that we may contact you to better understand the comments
								you submitted.
							</p>
						</article>
						<div>
							<ContactForm type="feedback" />
						</div>
					</div>
					<BackButton />
				</div>
			</div>
			<Footer />
			<ChatHistory
				onNavigate={(path: string) => {
					navigate(path);
				}}
			/>
		</section>
	);
};

export default Feedback;
