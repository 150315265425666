import { useNavigate } from "react-router-dom";
import LogoIcon from "../../assets/svg/logoIcon";
import BackButton from "../common/backButton";
import ChatHistory from "../common/chatHistory";
import Footer from "../common/footer";
import {
	defaultColumns,
	defaultGovernanceColumns,
} from "../../utils/constants";
import AdminTables from "../common/adminTables";
import createAxiosInstance from "../../utils/axiosConfig";
import { get_form_data } from "../../utils/endpoints";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";

const ViewFormData = () => {
	const navigate = useNavigate();
	const { userData } = useAuth();
	const [formData, setFormData] = useState<{
		results: [];
		total_count: number;
	}>({ results: [], total_count: 0 });
	const [useCase, setUseCase] = useState("use_case_3"); // New state for use case
	const [currentPage, setCurrentPage] = useState(1); // New state for current page
	const [itemsPerPage, setItemsPerPage] = useState(3); // New state for items per page
	const maxPages = Math.ceil(formData.total_count / itemsPerPage);

	// const itemsPerPage = 30; // Define items per page

	const getFormData = async () => {
		try {
			const response = await createAxiosInstance().post(get_form_data, {
				usecase: useCase,
				offset: (currentPage - 1) * itemsPerPage,
				limit: itemsPerPage,
			});
			setFormData(response.data);
		} catch (error) {
			console.log(error, "error");
		}
	};

	useEffect(() => {
		getFormData();
	}, [useCase, currentPage, itemsPerPage]);

	useEffect(() => {
		if (userData && !userData?.is_admin) {
			navigate("/");
		}
	}, [userData]);

	return (
		<section className="authentication" style={{ height: "90vh" }}>
			<div className="row align-items-center justify-content-center min-vh-90 w-100">
				<div
					className="col-md-10 col-lg-10 col-xl-12"
					style={{ overflow: "auto" }}
				>
					<div
						className="card mx-auto text-center mt-5 overflow-auto"
						style={{ overflow: "auto" }}
					>
						<div
							className="card-body"
							style={{
								paddingLeft: "1%",
								paddingRight: "1%",
								overflow: "auto",
							}}
						>
							<div className="mb-3">
								<LogoIcon />
							</div>
							<h2 className="my-1 my-md-1">Form Data</h2>
							<div className="mt-2 mb-3 flex flex-row justify-content-end">
								<div
									className="w-10 d-flex flex-column gap-1 text-start"
									style={{ width: "20%", float: "right" }}
								>
									<label htmlFor="floatingSelect">Select a use case:</label>
									<select
										value={useCase}
										onChange={(e) => setUseCase(e.target.value)}
										className="form-select mb-4"
										id="floatingSelect"
										aria-label="Floating label select example"
									>
										<option value="use_case_3">Use Case 3</option>
										<option value="use_case_7">Use Case 7</option>
									</select>
								</div>
							</div>
							<div className="mt-5">
								{!formData?.results || formData?.results?.length === 0 ? (
									<h3
										className="text-secondary"
										style={{
											textAlign: "center",
											marginTop: "7%",
											marginBottom: "5%",
										}}
									>
										No data available
									</h3>
								) : (
									<>
										<AdminTables
											data={formData?.results}
											columns={
												useCase === "use_case_3"
													? defaultColumns
													: defaultGovernanceColumns
											}
										/>
										<div className="pagination-controls mt-3 d-flex justify-content-between align-items-center">
											<div className="d-flex justify-content-center flex-grow-1 gap-5">
												<button
													disabled={currentPage === 1}
													onClick={() => setCurrentPage(currentPage - 1)}
													className="btn btn-sm btn-primary"
													style={{
														width: "3%",
														height: "3%",
														padding: "0px 0px",
													}}
												>
													&lt;
												</button>
												<span style={{}}>
													Showing {currentPage} to {currentPage * itemsPerPage}{" "}
													out of {formData.total_count || 0} entries
												</span>
												<button
													disabled={currentPage === maxPages}
													onClick={() => setCurrentPage(currentPage + 1)}
													className="btn btn-sm btn-primary"
													style={{
														width: "3%",
														height: "3%",
														padding: "0px 0px",
													}}
												>
													&gt;
												</button>
											</div>
											<div className="">
												<label htmlFor="itemsPerPageSelect">
													Items per page:
												</label>
												<select
													value={itemsPerPage}
													onChange={(e) =>
														setItemsPerPage(Number(e.target.value))
													}
													className="form-select mb-4"
													id="itemsPerPageSelect"
													aria-label="Items per page select"
												>
													<option value={3}>3</option>
													<option value={10}>10</option>
													<option value={20}>20</option>
													<option value={30}>30</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={150}>150</option>
													<option value={200}>200</option>
													<option value={250}>250</option>
												</select>
											</div>
										</div>
									</>
								)}
							</div>
							<div className="mb-3">
								<BackButton />
							</div>
						</div>
					</div>
				</div>
				<Footer />
				<ChatHistory
					onNavigate={(path: string) => {
						navigate(path);
					}}
				/>
			</div>
		</section>
	);
};

export default ViewFormData;
