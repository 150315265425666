import { useEffect, useState } from "react";
import LogoIcon from "../../assets/svg/logoIcon";
import BackButton from "../common/backButton";
import ChatHistory from "../common/chatHistory";
import Footer from "../common/footer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import { validate } from "../../utils/formValidate";
import createAxiosInstance from "../../utils/axiosConfig";
import { add_vendor_data, add_governance_data } from "../../utils/endpoints";
import Alert from "../common/alerts/alert";

const AddData = () => {
	const navigate = useNavigate();
	const { userData } = useAuth();
	const [option, setOption] = useState(0);
	const [isFadingIn, setIsFadingIn] = useState(false);
	const [isFadingOut, setIsFadingOut] = useState(false);
	const [errors, setErrors] = useState<any>({});
	const [loading, setLoading] = useState(false);
	const [responseError, setResponseError] = useState("");
	const [alertVisible, setAlertVisible] = useState(false);

	const [vendorCategory, setVendorCategory] = useState("");

	const [formDataVendor, setFormDataVendor] = useState({
		vendor_name: "",
		product_name: "",
		vendor_category: "",
		deliveryModel: "",
		serviceType: "",
		positive_experiences: "",
		negative_experiences: "",
		advice_for_prospective_customers: "",
		third_party_rating: "",
		// user_name: "",
		other_category: "",
	});

	const [spellingErrors, setSpellingErrors] = useState<any>(null);
	const [showSpellingModal, setShowSpellingModal] = useState(false);
	const [ignoreSpelling, setIgnoreSpelling] = useState(false);

	const [formDataGovernance, setFormDataGovernance] = useState({
		question: "",
		answer: "",
		persona: "",
		maturityLevel: "",
	});

	const handleChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>
	) => {
		const { name, value } = e.target;
		setFormDataVendor({ ...formDataVendor, [name]: value });
		validate({ [name]: value }, setErrors);

		if (name === "vendor_category") {
			setVendorCategory(value);
		}

		// Confirm password validation
		// if (name === "confirmPassword") {
		// 	if (value !== formData.password) {
		// 		// setConfirmPasswordError("Passwords do not match.");
		// 		setErrors({ ...errors, confirmPassword: "Passwords do not match." });
		// 	} else {
		// 		setErrors({ ...errors, confirmPassword: "" });
		// 	}
		// }
	};

	const handleGovernanceChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>
	) => {
		const { name, value } = e.target;
		setFormDataGovernance({ ...formDataGovernance, [name]: value });
		validate({ [name]: value }, setErrors);
	};

	const handleGovernanceSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!validate(formDataGovernance, setErrors)) {
			console.log(errors);
			return;
		}

		const payload = {
			user_id: userData?.id,
			question: formDataGovernance.question,
			answer: formDataGovernance.answer,
			persona: formDataGovernance.persona,
			maturityLevel: formDataGovernance.maturityLevel,
		};

		if (!ignoreSpelling) {
			const spellingResults = await checkSpellings(payload);
			if (spellingResults && Object.keys(spellingResults).length > 0) {
				setSpellingErrors(spellingResults);
				setShowSpellingModal(true);
				return;
			}
		}

		try {
			const axiosInstance = createAxiosInstance();
			const response = await axiosInstance.post(add_governance_data, payload);

			if (response.data?.user?.error) {
				setResponseError(response?.data?.user?.error);
				setAlertVisible(true);
			} else {
				setResponseError("");
				setAlertVisible(true);
				setFormDataGovernance({
					question: "",
					answer: "",
					persona: "",
					maturityLevel: "",
				});
				setErrors({});
				setSpellingErrors({});
				setIgnoreSpelling(false);
			}
		} catch (e: any) {
			console.log(e, "error");
			setResponseError("Some errors occured. Please try again later.");
			setAlertVisible(true);
		}
	};

	const handleOptionSelect = (option: number) => {
		setIsFadingOut(true);
		setOption(option);
		setTimeout(() => {
			// setIsFadingOut(false);
			setIsFadingIn(true);
		}, 1000);
	};

	// Add new function to check spellings
	const checkSpellings = async (payload: any) => {
		try {
			const axiosInstance = createAxiosInstance();
			const response = await axiosInstance.post(
				"/upload/checkSpellings",
				payload
			);
			return response.data;
		} catch (error) {
			console.error("Error checking spellings:", error);
			return null;
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		// if (!validate(formDataVendor, setErrors)) {
		// 	console.log(errors);
		// 	return;
		// }

		// const formDataForValidation = { other_category, ...formDataVendor };
		const { other_category, ...formDataForValidation } = formDataVendor;

		// delete formDataForValidation.other_category;

		if (!validate(formDataForValidation, setErrors)) {
			console.log(errors);
			return;
		}

		const payload = {
			user_id: userData?.id,
			vendorName: formDataVendor.vendor_name,
			productName: formDataVendor.product_name,
			vendorCategory: formDataVendor.vendor_category,
			deliveryModel: formDataVendor.deliveryModel,
			serviceType: formDataVendor.serviceType,
			positiveExperience: formDataVendor.positive_experiences,
			negativeExperience: formDataVendor.negative_experiences,
			advice: formDataVendor.advice_for_prospective_customers,
			rating: formDataVendor.third_party_rating,
			// name: formDataVendor.user_name,
			other: formDataVendor.other_category,
		};
		// Check spellings if not ignored
		if (!ignoreSpelling) {
			const spellingResults = await checkSpellings(payload);
			if (spellingResults && Object.keys(spellingResults).length > 0) {
				setSpellingErrors(spellingResults);
				setShowSpellingModal(true);
				return;
			}
		}

		try {
			const axiosInstance = createAxiosInstance();
			const response = await axiosInstance.post(add_vendor_data, payload);

			if (response.data?.user?.error) {
				setResponseError(response?.data?.user?.error);
				setAlertVisible(true);
			} else {
				setResponseError("");
				setAlertVisible(true);
				setFormDataVendor({
					vendor_name: "",
					product_name: "",
					vendor_category: "",
					deliveryModel: "",
					serviceType: "",
					positive_experiences: "",
					negative_experiences: "",
					advice_for_prospective_customers: "",
					third_party_rating: "",
					// user_name: "",
					other_category: "",
				});
				setVendorCategory("");
				setErrors({});
				setSpellingErrors({});
				setIgnoreSpelling(false);
			}
		} catch (e: any) {
			console.log(e, "error");
			setResponseError("Some errors occured. Please try again later.");
			setAlertVisible(true);
		}
	};

	// useEffect(() => {
	// 	if (
	// 		!userData?.is_admin ||
	// 		userData?.is_admin == 0
	// 		// || userData?.account_type <= 1
	// 	) {
	// 		navigate("/");
	// 	}
	// }, [option]);

	// Add SpellingModal component
	const SpellingModal = ({ show, onHide, onIgnore }: any) => {
		return (
			<div
				className={`modal ${show ? "d-block" : ""}`}
				style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header" style={{ border: "none" }}>
							<h5 className="modal-title">Spelling Mistakes Found</h5>
							<button
								type="button"
								className="btn-close"
								onClick={onHide}
							></button>
						</div>
						<div className="modal-body">
							<p>Please check the highlighted fields for spelling mistakes.</p>
						</div>
						<div className="modal-footer" style={{ border: "none" }}>
							<button
								type="button"
								className="btn btn-primary"
								onClick={onHide}
							>
								Fix Typos
							</button>
							<button
								type="button"
								className="btn btn-secondary"
								onClick={onIgnore}
							>
								Ignore and submit
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (ignoreSpelling) {
			if (option == 1) {
				handleSubmit(new Event("submit") as any);
			} else {
				handleGovernanceSubmit(new Event("submit") as any);
			}
		}
	}, [ignoreSpelling]);

	return (
		<section className="authentication h-100">
			<div className="row align-items-center justify-content-center min-vh-96 w-100">
				<div className="col-md-10 col-lg-10 col-xl-7">
					<div className="card  mx-auto text-center mt-5">
						<div className="card-body">
							<div className="mb-3">
								<LogoIcon />
							</div>
							<h2 className="my-3 my-md-4">
								Add{" "}
								{option === 1
									? "Vendor Selection"
									: option === 2
									? "Governance"
									: "Ingestion"}{" "}
								Data
							</h2>
							{userData?.is_admin && (
								<p className="text-secondary">
									want to upload a file instead ?{" "}
									<a href="/uploadFiles">Upload Data</a>
								</p>
							)}

							{/* <form action="" onSubmit={handleSubmit}> */}
							{((option !== 1 && option !== 2) || !isFadingIn) && (
								<ul
									className={`${
										option !== 1 && option !== 2 ? "fade-in" : "fade-out"
									} list-group checklist`}
								>
									<li
										className="list-group-item"
										style={{
											background: "#ebe6e3",
											cursor: "pointer",
											textAlign: "left",
										}}
										onClick={() => handleOptionSelect(1)}
									>
										<input
											className="form-check-input me-1"
											type="radio"
											name="listGroupRadio"
											id="firstRadio"
											checked={option === 1} // Check against the state
											style={{ cursor: "pointer" }}
										/>
										<label
											className="form-check-label"
											htmlFor="firstRadio"
											style={{ cursor: "pointer" }}
										>
											I want to add data for Vendor Selection.
										</label>
									</li>
									<li
										className="list-group-item"
										style={{
											background: "#ebe6e3",
											cursor: "pointer",
											textAlign: "left",
										}}
										onClick={() => handleOptionSelect(2)}
									>
										<input
											className="form-check-input me-1"
											type="radio"
											name="listGroupRadio"
											id="secondRadio"
											checked={option === 2} // Check against the state
											style={{ cursor: "pointer" }}
										/>
										<label
											className="form-check-label"
											htmlFor="secondRadio"
											style={{ cursor: "pointer" }}
										>
											I want to add data for Board/Governance and Management
											Oversight.
										</label>
									</li>
								</ul>
							)}

							{option == 1 && isFadingIn && (
								<form action="" onSubmit={handleSubmit} className="fade-in">
									<p className="text-secondary" style={{ textAlign: "left" }}>
										Vendor Selection:
									</p>

									<div className="d-flex flex-column flex-md-row gap-3">
										<div className="form-floating mb-3 w-100">
											<input
												type="text"
												className={`form-control`}
												id="floatingInput"
												placeholder="Name of vendor"
												name="vendor_name"
												value={formDataVendor.vendor_name}
												onChange={handleChange}
											/>
											<label htmlFor="floatingInput">Name of Vendor</label>
											{errors.vendor_name && (
												<div className="text-danger small">
													{errors.vendor_name}
												</div>
											)}

											{spellingErrors?.vendorName && (
												<div className="text-danger small">
													Detected a possible spelling mistake in the Name of
													Vendor field.
												</div>
											)}
										</div>

										<div className="form-floating mb-3 w-100">
											<input
												type="text"
												className={`form-control`}
												id="floatingInput"
												placeholder="Name of vendor"
												name="product_name"
												value={formDataVendor.product_name}
												onChange={handleChange}
											/>
											<label htmlFor="floatingInput">Name of Product</label>
											{errors.product_name && (
												<div className="text-danger small">
													{errors.product_name}
												</div>
											)}
											{spellingErrors?.productName && (
												<div className="text-danger small">
													Detected a possible spelling mistake in the Name of
													Product field.
												</div>
											)}
											{/* {errors.password && (
											<div className="text-danger small">{errors.password}</div>
										)} */}
										</div>
									</div>

									<div className="form-floating mb-3 ">
										<select
											className={`form-select`}
											id="floatingSelect"
											aria-label="Floating label select example"
											name="vendor_category"
											value={vendorCategory}
											onChange={handleChange}
											// value={deleteFormData.file_name}
											// onChange={handleSelectChange}
										>
											<option>Select a category</option>
											<option value="Database and Storage Security">
												Database and Storage Security
											</option>
											<option value="Edge Location Security">
												Edge Location Security
											</option>
											<option value="Encryption of Cloud Data at Rest, in Motion, and in Use">
												Encryption of Cloud Data at Rest, in Motion, and in Use
											</option>
											<option value="Infrastructure Security">
												Infrastructure Security
											</option>
											<option value="Networking Security">
												Networking Security
											</option>
											<option value="Anti-malware Software">
												Anti-malware Software
											</option>
											<option value="Antivirus Systems">
												Antivirus Systems
											</option>
											<option value="Backup">Backup</option>
											<option value="Data Loss Prevention (DLP)">
												Data Loss Prevention (DLP)
											</option>
											<option value="Enterprise Mobility Management">
												Enterprise Mobility Management
											</option>
											<option value="Encryption">Encryption</option>
											<option value="Endpoint Detection and Response (EDR)">
												Endpoint Detection and Response (EDR)
											</option>
											<option value="Enterprise Mobility Management (EMM)">
												Enterprise Mobility Management (EMM)
											</option>
											<option value="Firewalls">Firewalls</option>
											<option value="Identity and Access Management (IAM)">
												Identity and Access Management (IAM)
											</option>
											<option value="Intrusion Detection and Prevention System (IDPS)">
												Intrusion Detection and Prevention System (IDPS)
											</option>
											<option value="Mobile Application Management (MAM)">
												Mobile Application Management (MAM)
											</option>
											<option value="Multifactor Authentication (MFA)">
												Multifactor Authentication (MFA)
											</option>
											<option value="Network Access Control (NAC)">
												Network Access Control (NAC)
											</option>
											<option value="Next-generation Firewall (NGFW)">
												Next-generation Firewall (NGFW)
											</option>
											<option value="Secure Access Service Edge (SASE)">
												Secure Access Service Edge (SASE)
											</option>
											<option value="Secure Email Gateways (SEG)">
												Secure Email Gateways (SEG)
											</option>
											<option value="Security Information and Event Management (SIEM)">
												Security Information and Event Management (SIEM)
											</option>
											<option value="Security Orchestration, Automation, and Response (SOAR)">
												Security Orchestration, Automation, and Response (SOAR)
											</option>
											<option value="User and Entity Behavior Analytics (UEBA)">
												User and Entity Behavior Analytics (UEBA)
											</option>
											<option value="Virtual Private Networks (VPNs)">
												Virtual Private Networks (VPNs)
											</option>
											<option value="Web Application Firewalls (WAFs)">
												Web Application Firewalls (WAFs)
											</option>
											<option value="Third Party Risk Management (TPRM)">
												Third Party Risk Management (TPRM)
											</option>
											<option value="Governance, Risk, and Compliance (GRC)">
												Governance, Risk, and Compliance (GRC)
											</option>
											<option value="Cyber Risk Quantification (CRQ)">
												Cyber Risk Quantification (CRQ)
											</option>
											<option value="Network Detection and Response (NDR)">
												Network Detection and Response (NDR)
											</option>
											<option value="Other">Other</option>
										</select>
										<label htmlFor="floatingSelect">Vendor Category</label>
										{errors.vendor_category && (
											<div className="text-danger small">
												{errors.vendor_category}
											</div>
										)}
									</div>

									{vendorCategory === "Other" && (
										<div className="form-floating mb-3">
											<input
												type="text"
												className="form-control"
												id="otherCategory"
												placeholder="Specify other category"
												name="other_category"
												value={formDataVendor.other_category}
												onChange={handleChange}
											/>
											<label htmlFor="otherCategory">
												Specify Other Category
											</label>
											{errors.other_category && (
												<div className="text-danger small">
													{errors.other_category}
												</div>
											)}
										</div>
									)}
									<div className="form-floating mb-4 mt-4">
										<p className="text-secondary" style={{ textAlign: "left" }}>
											Delivery Model:
										</p>
										<div className="list-group-item d-flex align-items-center justify-content-start gap-3 mb-3">
											<input
												className="form-check-input me-1"
												type="radio"
												id="cloudOption"
												name="deliveryModel"
												value="Cloud"
												checked={formDataVendor.deliveryModel === "Cloud"}
												onChange={handleChange}
												// onChange={handleDeliveryModelChange} // Uncomment and implement if needed
											/>
											<label
												className="form-check-label"
												htmlFor="cloudOption"
												style={{ marginRight: "20px" }}
											>
												Cloud
											</label>
											<input
												className="form-check-input me-1"
												type="radio"
												id="onPremiseOption"
												name="deliveryModel"
												value="On-Premise"
												checked={formDataVendor.deliveryModel === "On-Premise"}
												onChange={handleChange}
												// onChange={handleDeliveryModelChange} // Uncomment and implement if needed
											/>
											<label
												className="form-check-label"
												htmlFor="onPremiseOption"
											>
												On-Premise
											</label>
										</div>
										{errors.deliveryModel && (
											<div className="text-danger small">
												{errors.deliveryModel}
											</div>
										)}
									</div>

									<div className="form-floating mb-4 mt-4">
										<p className="text-secondary" style={{ textAlign: "left" }}>
											Select the type of service/solution:
										</p>
										<div className="list-group-item d-flex flex-column flex-md-row align-items-start justify-content-start gap-3 mb-3">
											<div>
												<input
													type="radio"
													id="productInstalled"
													name="serviceType"
													value="Product (installed)"
													className="form-check-input me-1"
													onChange={handleChange}
													checked={
														formDataVendor.serviceType === "Product (installed)"
													}
													// onChange={handleServiceTypeChange} // Uncomment and implement if needed
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="productInstalled"
													style={{ marginRight: "20px" }}
													className="form-check-label"
												>
													Product (installed)
												</label>
											</div>
											<div>
												<input
													type="radio"
													id="serviceConsumed"
													name="serviceType"
													value="Service (consumed)"
													className="form-check-input me-1"
													onChange={handleChange}
													checked={
														formDataVendor.serviceType === "Service (consumed)"
													}
													// onChange={handleServiceTypeChange} // Uncomment and implement if needed
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="serviceConsumed"
													style={{ marginRight: "20px" }}
													className="form-check-label"
												>
													Service (consumed)
												</label>
											</div>
											<div>
												<input
													type="radio"
													id="professionalServices"
													name="serviceType"
													value="Professional Services (installed / supported)"
													className="form-check-input me-1"
													onChange={handleChange}
													checked={
														formDataVendor.serviceType ===
														"Professional Services (installed / supported)"
													}
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="professionalServices"
													style={{ marginRight: "20px" }}
													className="form-check-label"
												>
													Professional Services (installed / supported)
												</label>
											</div>
											<div>
												<input
													type="radio"
													id="resellerVAR"
													name="serviceType"
													value="Reseller / VAR (acquired / renewed)"
													className="form-check-input me-1"
													onChange={handleChange}
													checked={
														formDataVendor.serviceType ===
														"Reseller / VAR (acquired / renewed)"
													}
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="resellerVAR"
													style={{ marginRight: "20px" }}
													className="form-check-label"
												>
													Reseller / VAR (acquired / renewed)
												</label>
											</div>
											<div>
												<input
													type="radio"
													id="trainingProvided"
													name="serviceType"
													value="Training (provided / consumed)"
													className="form-check-input me-1"
													onChange={handleChange}
													checked={
														formDataVendor.serviceType ===
														"Training (provided / consumed)"
													}
													// onChange={handleServiceTypeChange} // Uncomment and implement if needed
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="trainingProvided"
													className="form-check-label"
												>
													Training (provided / consumed)
												</label>
											</div>
										</div>
										{errors.serviceType && (
											<div className="text-danger small">
												{errors.serviceType}
											</div>
										)}
									</div>

									<div className="d-flex flex-column flex-md-row gap-3">
										<div className="form-floating mb-3 w-100">
											<input
												type="text"
												className="form-control"
												id="positive_experiences"
												placeholder="Specify other category"
												name="positive_experiences"
												value={formDataVendor.positive_experiences}
												onChange={handleChange}
											/>
											<label
												htmlFor="positive_experiences text-wrap"
												style={{ whiteSpace: "wrap", textOverflow: "ellipsis" }}
												className="col-form-label"
											>
												What were three Positive experiences?
											</label>
											{errors.positive_experiences && (
												<div className="text-danger small">
													{errors.positive_experiences}
												</div>
											)}

											{spellingErrors?.positiveExperience && (
												<div className="text-danger small">
													Detected a possible spelling mistake in the Positive
													experiences field.
												</div>
											)}
										</div>

										<div className="form-floating mb-3 w-100">
											<input
												type="text"
												className="form-control"
												id="negative_experiences"
												placeholder="Specify other category"
												name="negative_experiences"
												value={formDataVendor.negative_experiences}
												onChange={handleChange}
											/>
											<label
												htmlFor="negative_experiences"
												className="flex-wrap col-form-label"
												style={{ whiteSpace: "wrap", textOverflow: "ellipsis" }}
											>
												What were three Negative experiences?
											</label>
											{errors.negative_experiences && (
												<div className="text-danger small">
													{errors.negative_experiences}
												</div>
											)}
											{spellingErrors?.negativeExperience && (
												<div className="text-danger small">
													Detected a possible spelling mistake in the Negative
													experiences field.
												</div>
											)}
										</div>
									</div>
									<div className="form-floating mb-3">
										<input
											type="text"
											className="form-control"
											id="advice_for_prospective_customers"
											placeholder="Specify other category"
											name="advice_for_prospective_customers"
											value={formDataVendor.advice_for_prospective_customers}
											onChange={handleChange}
										/>
										<label
											className="col-form-label"
											style={{ whiteSpace: "wrap", textOverflow: "ellipsis" }}
											htmlFor="advice_for_prospective_customers"
										>
											What advice do you have for prospective customers before
											they buy?
										</label>
										{errors.advice_for_prospective_customers && (
											<div className="text-danger small">
												{errors.advice_for_prospective_customers}
											</div>
										)}
										{spellingErrors?.advice && (
											<div className="text-danger small">
												Detected a possible spelling mistake in the advice
												field.
											</div>
										)}
									</div>

									<div className="form-floating mb-4 mt-4">
										<p className="text-secondary" style={{ textAlign: "left" }}>
											Overall vendor / third party rating:
										</p>
										<div className="list-group-item d-flex flex-column flex-md-row align-items-start justify-content-start gap-3 mb-3">
											<div>
												<input
													type="radio"
													id="third_party_rating_1"
													name="third_party_rating"
													value="1"
													className="form-check-input me-1"
													checked={formDataVendor.third_party_rating === "1"}
													onChange={handleChange}
													// onChange={handleServiceTypeChange} // Uncomment and implement if needed
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="third_party_rating_1"
													style={{ marginRight: "20px" }}
													className="form-check-label"
												>
													1 (Avoid)
												</label>
											</div>
											<div>
												<input
													type="radio"
													id="third_party_rating_2"
													name="third_party_rating"
													value="2"
													className="form-check-input me-1"
													checked={formDataVendor.third_party_rating === "2"}
													onChange={handleChange}
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="third_party_rating_2"
													style={{ marginRight: "20px" }}
													className="form-check-label"
												>
													2 (Below average)
												</label>
											</div>
											<div>
												<input
													type="radio"
													id="third_party_rating_3"
													name="third_party_rating"
													value="3"
													className="form-check-input me-1"
													checked={formDataVendor.third_party_rating === "3"}
													onChange={handleChange}
													// onChange={handleServiceTypeChange} // Uncomment and implement if needed
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="third_party_rating_3"
													style={{ marginRight: "20px" }}
													className="form-check-label"
												>
													3 (Neutral rating)
												</label>
											</div>
											<div>
												<input
													type="radio"
													id="third_party_rating_4"
													name="third_party_rating"
													value="4"
													className="form-check-input me-1"
													checked={formDataVendor.third_party_rating === "4"}
													onChange={handleChange}
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="third_party_rating_4"
													className="form-check-label"
												>
													4 (Above average)
												</label>
											</div>

											<div>
												<input
													type="radio"
													id="third_party_rating_5"
													name="third_party_rating"
													value="5"
													className="form-check-input me-1"
													checked={formDataVendor.third_party_rating === "5"}
													onChange={handleChange}
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="third_party_rating_5"
													className="form-check-label"
												>
													5 (Outstanding Partner)
												</label>
											</div>
										</div>
										{errors.third_party_rating && (
											<div className="text-danger small">
												{errors.third_party_rating}
											</div>
										)}
									</div>

									{/* <div className="form-floating mb-4">
										<input
											type="text"
											className="form-control"
											id="user_name"
											placeholder="Specify other category"
											name="user_name"
											value={formDataVendor.user_name}
											onChange={handleChange}
										/>
										<label htmlFor="user_name">Full Name</label>
										{errors.user_name && (
											<div className="text-danger small">
												{errors.user_name}
											</div>
										)}
									</div> */}
									<div className="mb-3">
										<button
											type="submit"
											className="btn btn-primary w-100"
											onClick={handleSubmit}
											disabled={loading}
										>
											Submit
										</button>
									</div>
								</form>
							)}

							{option == 2 && isFadingIn && (
								<form
									action=""
									onSubmit={handleGovernanceSubmit}
									className="fade-in"
								>
									<p className="text-secondary" style={{ textAlign: "left" }}>
										Governance and Management Oversight:
									</p>

									<div className="form-floating mb-3 ">
										<select
											className={`form-select`}
											id="floatingSelect"
											aria-label="Floating label select example"
											name="persona"
											value={formDataGovernance.persona}
											onChange={handleGovernanceChange}
											// value={deleteFormData.file_name}
											// onChange={handleSelectChange}
										>
											<option>Select a user persona</option>
											<option value="Director">Director</option>
											<option value="Executive">Executive</option>
											<option value="CEO">CEO</option>
											<option value="CFO">CFO</option>
											<option value="Auditor">Auditor</option>
										</select>
										<label htmlFor="floatingSelect">Persona</label>
										{errors.persona && (
											<div className="text-danger small">{errors.persona}</div>
										)}
									</div>

									<div className="form-floating mb-3">
										<input
											type="text"
											className="form-control"
											id="question"
											placeholder="Type your question here..."
											name="question"
											value={formDataGovernance.question}
											onChange={handleGovernanceChange}
										/>
										<label htmlFor="question">User Question</label>
										{errors.question && (
											<div className="text-danger small">{errors.question}</div>
										)}
										{spellingErrors?.question && (
											<div className="text-danger small">
												Detected a possible spelling mistake in the User
												Question field.
											</div>
										)}
									</div>

									<div className="form-floating mb-3">
										<input
											type="text"
											className="form-control"
											id="answer"
											placeholder="Type answer to the question here..."
											name="answer"
											value={formDataGovernance.answer}
											onChange={handleGovernanceChange}
										/>
										<label htmlFor="answer">System Answer</label>
										{errors.answer && (
											<div className="text-danger small">{errors.answer}</div>
										)}
										{spellingErrors?.answer && (
											<div className="text-danger small">
												Detected a possible spelling mistake in the System
												Answer field.
											</div>
										)}
									</div>

									<div className="form-floating mb-4 mt-4">
										<p className="text-secondary" style={{ textAlign: "left" }}>
											Familiarity with Information security processes:
										</p>
										<div className="list-group-item d-flex flex-column flex-md-row align-items-start justify-content-start gap-3 mb-4">
											<div>
												<input
													type="radio"
													id="Novice"
													name="maturityLevel"
													value="Novice"
													className="form-check-input me-1"
													checked={
														formDataGovernance.maturityLevel === "Novice"
													}
													onChange={handleGovernanceChange}
													// onChange={handleServiceTypeChange} // Uncomment and implement if needed
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="Novice"
													style={{ marginRight: "20px" }}
													className="form-check-label"
												>
													1 (Novice)
												</label>
											</div>
											<div>
												<input
													type="radio"
													id="Intermediate"
													name="maturityLevel"
													value="Intermediate"
													className="form-check-input me-1"
													checked={
														formDataGovernance.maturityLevel === "Intermediate"
													}
													onChange={handleGovernanceChange}
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="Intermediate"
													style={{ marginRight: "20px" }}
													className="form-check-label"
												>
													2 (Intermediate)
												</label>
											</div>
											<div>
												<input
													type="radio"
													id="Advanced"
													name="maturityLevel"
													value="Advanced"
													className="form-check-input me-1"
													checked={
														formDataGovernance.maturityLevel === "Advanced"
													}
													onChange={handleGovernanceChange}
													// onChange={handleServiceTypeChange} // Uncomment and implement if needed
												/>
												&nbsp;&nbsp;&nbsp;
												<label
													htmlFor="Advanced"
													style={{ marginRight: "20px" }}
													className="form-check-label"
												>
													3 (Advanced)
												</label>
											</div>
										</div>
										{errors.maturityLevel && (
											<div className="text-danger small">
												{errors.maturityLevel}
											</div>
										)}
									</div>
									<div className="mb-3 mt-3">
										<button
											type="submit"
											className="btn btn-primary w-100"
											onClick={handleGovernanceSubmit}
											disabled={loading}
										>
											Submit
										</button>
									</div>
								</form>
							)}

							{/* {responseError && <p>{responseError}</p>} */}
							<div className="mb-3">
								{option >= 1 ? (
									<div
										className="btn btn-link w-100"
										onClick={() => {
											handleOptionSelect(0);
										}}
									>
										{" "}
										<i className="bi bi-arrow-left me-2"></i> Back
									</div>
								) : (
									<BackButton />
								)}
							</div>
							{/* </form> */}
						</div>
					</div>
				</div>
				<Footer />
				<ChatHistory
					onNavigate={(path: string) => {
						navigate(path);
					}}
				/>
			</div>
			<Alert
				message={`${
					responseError.length > 1 ? responseError : "Data added successfully."
				}`}
				type={`${responseError.length > 1 ? "danger" : "success"}`}
				show={alertVisible}
				onClose={() => setAlertVisible(false)}
			/>
			<SpellingModal
				show={showSpellingModal}
				onHide={() => {
					setShowSpellingModal(false);
					// setSpellingErrors(null);
				}}
				onIgnore={() => {
					setIgnoreSpelling(true);
					setShowSpellingModal(false);
					// setTimeout(() => {
					// 	option == 1
					// 		? handleSubmit(new Event("submit") as any)
					// 		: handleGovernanceSubmit(new Event("submit") as any);
					// }, 0);
				}}
			/>
		</section>
	);
};

export default AddData;
