import React from "react";
import LogoIcon from "../../assets/svg/logoIcon";
import BackButton from "../common/backButton";
import Footer from "../common/footer";
import ChatHistory from "../common/chatHistory";
import { useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
	const navigate = useNavigate();
	return (
		<section className="authentication h-100">
			<div className="row align-items-center justify-content-center h-100 w-100">
				<div className="col-md-10 col-lg-9 col-xl-6">
					<div className="card  mx-auto ">
						<div className="text-start">
							<BackButton />
						</div>
						<div className="card-body">
							{/* <h1 className="my-3 my-md-4"> Terms And Conditions</h1>
              <p className="text-secondary">
                Have you already reset the password ? <a href="">Sign in</a>
              </p> */}

							<h1>Terms and Conditions</h1>

							<h3 className="my-3 my-md-4">1. Acceptance of Terms</h3>
							<p className="text-secondary">
								These terms of use (the <b>“Terms of Use“</b>) and the Order
								Form (if applicable) entered into between you (<b>“you”</b> or
								collectively, <b>“Users“</b>, as applicable) and 1CISO.io Inc.
								and its affiliated entities (<b>“1CISO.io“</b>, <b>“We“</b>,{" "}
								<b>“Our“</b>), governs your and your Authorized Users’ (as
								defined below) use of 1CISO.io various services (the{" "}
								<b>“Services“</b>) that enable you to provide User Data (as
								defined below) to 1CISO.io servers (the <b>“Server“</b>), and to
								analyze User Data (the “<b>1CISO.io Platform</b>“). These Terms
								of Use also stipulate the terms and conditions of your use of
								the 1CISO.io website (the <b>“Website“</b>).
							</p>
							<p className="text-secondary">
								Please note that the term “<b>you</b>” also includes the company
								that is our customer and on whose behalf you are accepting these
								Terms of Use. You confirm that you are authorized to bind the
								company you represent by these Terms of Use.
							</p>

							<p className="text-secondary">
								Before using any of the Services, please carefully read these
								Terms of Use. By accessing, browsing or using the Website,
								1CISO.io Platform, Services or Data (as defined below), you
								acknowledge that you have read, understood and agree to be bound
								by all the terms, conditions, consents and disclosures set forth
								in these Terms of Use. IF YOU DO NOT AGREE TO THESE TERMS OF
								USE, THEN PLEASE CEASE USING THE WEBSITE, 1CISO.IO PLATFORM,
								SERVICES OR DATA IMMEDIATELY.
							</p>

							<p className="text-secondary">
								The Services are intended solely for persons who are 18 or
								older. Any access to or use of the Website, 1CISO.io Platform,
								Services or Data by anyone under 18 is expressly prohibited. By
								accessing or using the Website, 1CISO.io Platform, Services or
								Data, you represent and warrant that you are 18 or older.
							</p>

							<p className="text-secondary">
								<b>“Authorized Users”</b> means individuals directly employed or
								contracted by you or by your company who have either registered
								to the 1CISO.io Platform or were provided with access thereto by
								you or by your company. You are responsible to ensure that all
								Authorized Users comply with the restrictions set forth in these
								Terms of Use and the Documentation (as defined below) and you
								shall remain solely liable towards 1CISO.io for the actions of
								such Authorized Users.
							</p>

							<h3>2. Modification of Terms of Use</h3>
							<p className="text-secondary">
								We reserve the right, at our sole discretion, to modify,
								discontinue or terminate the Services or to modify these Terms
								of Use, at any time and for any reason. If we modify these Terms
								of Use, we will post the modified Terms of Use on the Website or
								provide you with notice of the modification. We will also
								indicate the last update date at the top of these Terms of Use.
								By continuing to access or use the Services and/or the Website
								after we have posted a modified version on the Website or have
								provided you with notice of a modification, you are indicating
								that you agree to be bound by the modified Terms of Use. IF THE
								MODIFIED TERMS OF USE ARE NOT ACCEPTABLE TO YOU, THEN PLEASE
								CEASE USING THE WEBSITE, 1CISO.IO PLATFORM, SERVICES AND THE
								DATA IMMEDIATELY.
							</p>

							<h3>3. Privacy</h3>
							<p className="text-secondary">
								We consider your privacy one of our highest priorities. Please
								refer to our Privacy Policy, which is incorporated herein by
								reference, for information and notices concerning our collection
								and use of your personal and non-personal information
							</p>
							<p className="text-secondary">
								If you use the 1CISO.io Platform, Services or Website, you
								hereby represent, warrant and undertake that you will only use
								it to process User Data which you have the right to process, and
								in compliance with all applicable laws and regulations,
								including but not limited to applicable data security and
								privacy laws. You shall be solely responsible to provide any
								notice and obtain any consent from any data subject, all as
								required by applicable laws and regulations in connection with
								the collection, use and disclosure of any User Data that
								includes personal information or personally identifiable
								information to 1CISO.io by you.
							</p>
							<p className="text-secondary">
								If you provide User Data which contains Personal Data (as
								defined below), and such Personal Data is subject to the
								provisions of the General Data Protection Regulation (GDPR),
								then such sharing will be governed by 1CISO.io’s Data Processing
								Addendum, which can be accessed through this link.
							</p>
							<p className="text-secondary">
								For the purpose of this Section 3, “Personal Data” shall mean
								any information relating to an identified or identifiable
								natural person.
							</p>

							<h3>4. Limitations of Services</h3>
							<p className="text-secondary">
								You acknowledge that 1CISO.io may establish with respect to each
								of the Subscription Plans (as defined below), limits concerning
								use of the Services, including, without limitation, the maximum
								number of days that the User Data will be retained by the
								Services (the “<b>Retention Period</b>“), the frequency with
								which you may access the Services, the 3rd party cloud providers
								where the User Data will be stored and their locations, etc.
							</p>

							<p className="text-secondary">
								Any User Data that you send to the 1CISO.io Platform shall be in
								accordance with your Subscription Plan and the Documentation.
								Customer may ship User Data according to its Subscription Plans,
								to be calculated daily, based on the amount of User Data that is
								sent to the 1CISO.io Platform and enriched, as measured by the
								1CISO.io Platform from 00:00 UTC to 23:59:999 UTC (the “Daily
								Data Capacity“). In the event that you send User Data in excess
								of your Subscription Plan, then 1CISO.io reserves the right to
								charge additional fees with respect thereto, as set forth in the
								applicable Order Form or in 1CISO.io’s pricing page available
								at: https://1CISO.io/pricing/ (the “Pricing Page“).
							</p>

							<p className="text-secondary">
								You agree that 1CISO.io has no responsibility or liability for
								the deletion of or failure to store any User Data or other
								content maintained or transmitted by or through the Services
							</p>

							<p className="text-secondary">
								Solely with respect to Users with an Account that is under a
								Paid Plan- 1CISO.io shall employ commercially reasonable efforts
								to make the Services available according to the following target
								uptime availability- https://1CISO.io/about-us/1ciso.io-sla.
							</p>

							<h3>5. Account Registration and Fees</h3>
							<p className="text-secondary">
								In order to gain access to certain features of the 1CISO.io
								Platform, you must register and create an account with 1CISO.io
								via the Website. You agree to provide accurate information
								during the registration process, to update such information and
								to keep it current and complete, at all times. 1CISO.io reserves
								the right to suspend or terminate your Account at any time,
								including if any information provided during the registration
								process or thereafter proves to be inaccurate, not current or
								incomplete. You are solely responsible for safeguarding your
								Account’s credentials (such as your username and password). You
								agree that you will not disclose your Account’s credentials to
								any third party other than to your Authorized Users and that you
								will take sole responsibility for any activities or actions
								under your Account, including those of your Authorized Users,
								whether or not you have authorized such activities or actions.
								You will immediately notify 1CISO.io of any unauthorized use of
								your Account. An administrator of an Account may authorize an
								unlimited number of Authorized Users per Account by sending them
								an invitation to join the Account. The administrators will
								administer the use of such Account by such Authorized Users.
							</p>

							<p className="text-secondary">
								1CISO.io offers trial, free and various paid subscription plans
								(a “Trial Plan“, “Community Plan” and “Paid Plan“, respectively,
								and collectively, the “Subscription Plans“) for all Accounts. A
								description of the features associated with these Subscription
								Plans is available on the Website.
							</p>

							<p className="text-secondary">
								All new Accounts start with a Trial Plan. Following the lapse of
								the applicable trial period under a Trial Plan, a Community Plan
								will automatically apply to an Account, unless you select to
								subscribe to a Paid Plan. When you select a Paid Plan, you will
								need to select a payment method. If you elect to pay through a
								credit card, you will be required to provide the third party
								clearance services provider with a credit card number and
								customary billing information such as your name and billing
								address. Invoices will be sent to you through such third party
								clearance services provider. If you elect to pay through wire
								transfer, you will pay our fees through the wire instructions we
								will provide. If available, 1CISO.io may also enable you to pay
								via one of the available marketplaces that offer the 1CISO.io
								Platform, such as the AWS Marketplace or the Azure Marketplace
							</p>

							<p className="text-secondary">
								Your subscription period will commence on the start date and end
								on the end date, as set forth in your Order Form or in any other
								written agreement with the Company (the “
								<b>Subscription Period</b>“). You will be automatically charged
								the subscription fee in advance of each Subscription Period,
								unless stated otherwise in the Order Form or in any other signed
								agreement between you and 1CISO.io. In the event that your
								Subscription Period is on a month-to-month basis, then you may
								cancel your Account by contacting us in writing to
								info@1ciso.io, at least thirty (30) days prior to the renewal of
								such Subscription Period.
							</p>

							<p className="text-secondary">
								During the Subscription Period, you have the option to use
								additional priced features, capabilities, and services offered
								by 1CISO.io via the 1CISO.io Platform (“Additional Services”),
								and the calculation of the Additional Services’ usage and fees
								will be in accordance with the prices, as set forth on
								1CISO.io’s Pricing Page available at: https://1CISO.io/pricing.
								At any time, your administrator may disable any access to the
								Additional Services via the 1CISO.io Platform or via the Support
								Chat.
							</p>

							<p className="text-secondary">
								Fees are due Net 30 days from the date of invoice (unless stated
								otherwise in the Order Form or in any other signed agreement
								between you and 1CISO.io). All payments not made when due shall
								be subject to a late charge of 1.5% per month or the maximum
								rate permitted by law, whichever is lower, from the date such
								payment was due until the date paid. Furthermore, 1CISO.io
								reserves the right to suspend the Services provided to you until
								unpaid amounts are paid or to terminate your Account. All fees
								are based on Services purchased and not on actual usage. All
								fees are non-cancellable and non-refundable. All fees and
								applicable taxes and banking fees, if any, are payable in United
								States dollars and are exclusive of all taxes, levies, or duties
								imposed by taxing authorities. You shall be responsible for
								payment of all such taxes, levies, duties, bank fees and other
								fees that may accrue in relation to your use of the Services.
								1CISO.io is not responsible for any expenses or third party
								charges (including, without limitation, any charges with respect
								to clearing or billing services) you incur as a result of being
								billed in accordance with these Terms of Use. 1CISO.io also
								reserves the right, at any time, to impose or restructure any
								fees charged for your use of the Services or the Additional
								Services. 1CISO.io will notify you in advance of any changes
								with respect to fees it chooses to impose
							</p>

							<p className="text-secondary">
								If 1CISO.io decides to cancel your Account, 1CISO.io may, at its
								discretion, provide you with notice via e-mail. If 1CISO.io
								receives a bounce-back from an e-mail account to which 1CISO.io
								sent a notice regarding the cancellation of your Account,
								1CISO.io is under no obligation to provide you with further
								notice.
							</p>

							<h3>6. Use of Shippers and Other Third Party Software</h3>
							<p className="text-secondary">
								You acknowledge and agree that the Services (including the
								Shippers) may include, or require you to install and use,
								certain publicly available software (“
								<b>Third Party Software</b>”), as specified on the Website, in
								the Documentation and in the 1CISO.io Platform. “<b>Shippers</b>
								” means certain libraries and programs available through
								1CISO.io Platform or otherwise, that enable Users to collect
								logs containing User Data and to ship them to the Server where
								such User Data shall be stored for a limited Retention Period,
								all as set forth in the applicable Order Form. You agree to be
								bound by the terms governing the Third Party Software with
								regards to the use thereof. 1CISO.io does not, and shall not,
								assume any responsibility or liability with respect to any Third
								Party Software, its integrity, completeness, accuracy,
								reliability and/or quality, and 1CISO.io does not guarantee or
								assume any responsibility or liability that any such Third Party
								Software will function as described by the third party providing
								it. Furthermore, 1CISO.io does not, and shall not, assume any
								responsibility or liability to any content offered, published,
								displayed and/or suggested through any Third Party Software.
							</p>
							<p className="text-secondary">
								In addition, you acknowledge and agree that the use of the
								Insights feature is based on publicly available resources on the
								Internet and on AI-generated content. As such, these suggestions
								may be incomplete, inaccurate and/or contain errors. 1CISO.io
								makes no representations or warranties of any kind, express or
								implied, about the completeness, accuracy or availability of
								these insights or the use thereof.
							</p>

							<h3>7. Ownership</h3>
							<p className="text-secondary">
								Except as expressly provided in these Terms of Use, 1CISO.io and
								its licensors exclusively own all right, title and interest in
								and to the Services, the 1CISO.io Platform, the Website, the
								Documentation and 1CISO.io Data (as defined below), including
								all associated intellectual property rights. You will not
								remove, alter or obscure any copyright, trademark, service mark
								or other proprietary rights notices incorporated in or
								accompanying the Services, the 1CISO.io Platform, the Website,
								the Documentation and 1CISO.io Data.
							</p>
							<p className="text-secondary">
								All trademarks, service marks, logos, trade names and any other
								proprietary designations of 1CISO.io used herein are trademarks
								of 1CISO.io. Any other trademarks, service marks, logos, trade
								names and any other proprietary designations are the trademarks
								of their respective owners.
							</p>
							<p className="text-secondary">
								1CISO.io does not claim any ownership rights in your User Data
								and nothing in these Terms of Use will be deemed to restrict any
								rights that you may have to use and exploit your User Data.
							</p>
							<p className="text-secondary">
								For purposes of these Terms of Use: (i) “Data” means data,
								customized analyses, text, graphics, images, music, software,
								audio, video, works of authorship of any kind, and information
								or other materials that are posted, provided or otherwise made
								available through the 1CISO.io Platform; and (ii) “User Data”
								means any Data that is either shipped by Shippers to the Server
								or otherwise made available to 1CISO.io, and (iii) “1CISO.io
								Data” means any Data that is not User Data.
							</p>
							<h3>8. Licenses Granted by 1CISO.io to You</h3>
							<p className="text-secondary">
								During the term of your engagement with 1CISO.io and subject to
								your full compliance with these Terms of Use, 1CISO.io grants
								you a limited, revocable, non-exclusive, non-transferable,
								non-sublicenseable license for your Authorized Users to access
								and use the 1CISO.io Platform for your internal business
								purposes and subject to the restrictions set forth in these
								Terms of Use. Any use of the 1CISO.io Platform shall be in
								accordance with 1CISO.io’s instructions and technical
								documentation made available through the 1CISO.io Platform and
								through{" "}
								<a href="https://docs.1CISO.io/">https://docs.1CISO.io/</a> (“
								<b>Documentation</b>“). You will not use, copy, adapt, modify,
								prepare derivative works based upon, distribute, license, sell,
								transfer, publicly display, publicly perform, transmit, stream,
								broadcast or otherwise exploit the Services or the 1CISO.io
								Platform, except as expressly permitted in these Terms of Use or
								under a written agreement with 1CISO.io. No licenses or rights
								are granted to you by implication or otherwise under any
								intellectual property rights owned or controlled by 1CISO.io or
								its licensors, except for the licenses and rights expressly
								granted in these Terms of Use.
							</p>

							<h3>9. Licenses Granted by You to 1CISO.io</h3>
							<p className="text-secondary">
								By making any User Data available through the 1CISO.io Platform,
								you hereby grant 1CISO.io a non-exclusive, transferable,
								sublicenseable, worldwide, royalty-free license to use, copy,
								modify, publicly display and distribute your User Data for the
								purpose of providing the Services to you. You also grant
								1CISO.io the right to use your User Data to improve our
								Services, the 1CISO.io Platform and the Website.
							</p>

							<p className="text-secondary">
								You are solely responsible for all your User Data. You represent
								and warrant that (i) you own all your User Data or you have all
								rights that are necessary to grant us the license rights in your
								User Data under these Terms of Use; (ii) your User Data, or your
								use and provision of your User Data, or any use of your User
								Data by 1CISO.io on or through the 1CISO.io Platform will not
								infringe, misappropriate or violate a third party’s intellectual
								property rights, or rights of publicity or privacy, or result in
								the violation of any applicable law or regulation; (iii) you
								have fully complied with any third-party licenses, permits and
								authorizations required in connection with such User Data; (iv)
								the User Data does not contain any viruses, worms, Trojan horses
								or other harmful or destructive code or content; and (v) the
								User Data is not: (a) defamatory or libelous, (b) fraudulent,
								false, misleading or deceptive (c) promotes discrimination,
								racism, hatred, or violence.
							</p>

							<h3>User Restrictions</h3>
							<p className="text-secondary">
								You may only access and use the Services, the Website and the
								1CISO.io Platform solely for your internal business purposes.
								You will not: (i) sell, resell, rent or lease the Services, the
								Website or the 1CISO.io Platform; (ii) reverse engineer the
								Services, the Website or the 1CISO.io Platform; (iii) attempt to
								modify the Services, the Website or the 1CISO.io Platform; or
								(iv) use the Services, the Website or the 1CISO.io Platform to
								store any User Data or any information that is unlawful. You may
								not access or use (or permit a third party to access or use) the
								Services, the Website or the 1CISO.io Platform for purposes of
								monitoring the availability, performance or functionality of the
								Services, the Website or the 1CISO.io Platform or for any other
								benchmarking or competitive purposes. You will use the 1CISO.io
								Platform and the Services in compliance with the Documentation
								and with these Terms of Use, including as it relates to
								integration with your properties, and you shall not abuse,
								interfere, or misuse the Services and/or the 1CISO.io Platform,
								in any way. You shall be solely and absolutely liable towards
								1CISO.io for any damages or disruptions to the Services, the
								Website or the 1CISO.io Platform caused by your use of the
								Services in violation of the Documentation and of these Terms of
								Use. You will not attempt to gain access to any related systems
								or networks to which access is restricted.
							</p>

							<p className="text-secondary">
								In addition, you agree not to do any of the following in
								connection with your use of the Services, or with your access to
								the 1CISO.io Platform or the Website (including with respect to
								any third party services offered or linked therefrom):
							</p>

							<ul style={{ listStyleType: "disc" }}>
								<li>
									Post, upload, publish, submit or transmit any Data that: (i)
									infringes, misappropriates or violates a third party’s patent,
									copyright, trademark, trade secret, moral rights or other
									intellectual property rights, or rights of publicity or
									privacy; (ii) violates, or encourages any conduct that would
									violate, any applicable law or regulation or would give rise
									to civil liability; (iii) is fraudulent, false, misleading or
									deceptive; (iv) is defamatory, obscene, pornographic, vulgar
									or offensive; (v) promotes discrimination, bigotry, racism,
									hatred, harassment or harm against any individual or group;
									(vi) is violent or threatening or promotes violence or actions
									that are threatening to any other person; or (vii) promotes
									illegal or harmful activities or substances (including but not
									limited to activities that promote or provide instructional
									information regarding the manufacture or purchase of illegal
									weapons or illegal substances);
								</li>
								<li>
									Use, display, mirror, frame or utilize framing techniques to
									enclose the Website, or any individual element or materials
									within the Website, 1CISO.io’s name, any 1CISO.io trademark,
									logo or other proprietary information, the content of any text
									or the layout and design of any page or form contained on a
									page, without 1CISO.io’s express written consent;
								</li>
								<li>
									Access, tamper with, or use non-public areas of the Website,
									the 1CISO.io Platform, 1CISO.io’s computer systems, or the
									technical delivery systems of 1CISO.io’s providers;
								</li>
								<li>
									Attempt to probe, scan, test the vulnerability or breach any
									security or authentication measures of the Website, the
									1CISO.io Platform, or any 1CISO.io system or network;
								</li>
								<li>
									Avoid, bypass, remove, deactivate, impair, descramble or
									otherwise circumvent any technological measure implemented by
									1CISO.io or any of 1CISO.io’s providers or any other third
									party (including another user) to protect the Services, the
									Website and the 1CISO.io Platform;
								</li>
								<li>
									Attempt to access, search or download the Services, the
									Website or the 1CISO.io Platform through the use of any
									engine, software, tool, agent, device or mechanism (including
									spiders, robots, crawlers, data mining tools or the like)
									other than tools that access or search the Services, the
									Website or the 1CISO.io Platform through the software and/or
									search agents provided by 1CISO.io or other generally
									available third party Shippers or web browsers (such as
									Microsoft Internet Explorer, Mozilla Firefox, Safari, Opera or
									Chrome);
								</li>
								<li>
									Send any unsolicited or unauthorized advertising, promotional
									materials, e-mail, junk mail, spam, chain letters or other
									form of solicitation;
								</li>
								<li>
									Use any meta tags or other hidden text or metadata utilizing a
									1CISO.io trademark, logo URL or product name without
									1CISO.io’s express written consent;
								</li>
								<li>
									Use the Services, the Website and the 1CISO.io Platform in any
									manner not permitted by these Terms of Use;
								</li>
								<li>
									Forge any TCP/IP packet header or any part of the header
									information in any e-mail or newsgroup posting, or in any way
									use the Services, the Website or the 1CISO.io Platform to send
									altered, deceptive or false source-identifying information;
								</li>
								<li>
									Interfere with, or attempt to interfere with, the access of
									any user, host or network, including, without limitation,
									sending a virus, overloading, flooding, spamming, or
									mail-bombing the Services, the Website or the 1CISO.io
									Platform. Without derogating from the generality of the above,
									you specifically agree not to establish an excessive number of
									connections from IoT or mobile devices that may impact the
									performance or stability of the 1CISO.io Platform or any other
									network resource.
								</li>
								<li>
									Collect or store any personal data or personally identifiable
									information from the Services, the Website or the 1CISO.io
									Platform from other users of the Services, the Website and the
									1CISO.io Platform without their express prior permission, for
									commercial or unlawful purposes or otherwise;
								</li>
								<li>
									Impersonate or misrepresent your affiliation with any person
									or entity;
								</li>
								<li>Violate any applicable law or regulation;</li>
								<li>
									Contact anyone who has not asked not to be contacted, or make
									unsolicited contact with anyone for any commercial purpose;
								</li>
								<li>Stalk or otherwise harass anyone; or</li>
								<li>Repeatedly post the same or similar content.</li>
							</ul>

							<p className="text-secondary">
								1CISO.io has the right to investigate and prosecute violations
								of any of the above, including intellectual property rights
								infringement, Website security issues, to the fullest extent
								permitted by law. 1CISO.io may involve and cooperate with law
								enforcement authorities in prosecuting Users who violate these
								Terms of Use. You acknowledge that 1CISO.io has no obligation to
								monitor your access to or use of the Services or the 1CISO.io
								Platform or to review or edit any User Data, but has the right
								to do so for the purpose of operating the Services, to ensure
								your compliance with these Terms of Use, or to comply with
								applicable law or the order or requirement of a court,
								administrative agency or other governmental body. 1CISO.io
								reserves the right, at any time and without prior notice, to
								remove or disable access to any Data for any reason, including,
								but not limited to, any User Data, and/or to temporarily or
								indefinitely block access from offending mobile/IoT devices or
								implementing rate-limiting measures that 1CISO.io, in its sole
								discretion, considers to be in violation of these Terms of Use
								or otherwise harmful to the Services. These actions do not
								derogate from any other remedies available to 1CISO.io in
								accordance with these Terms of Use (specifically the right to
								terminate under Section 11) or applicable law.
							</p>
							<p className="text-secondary">
								By accessing, browsing or using the Website, 1CISO.io Platform,
								Services or Data, you represent that you are not the target of
								sanctions laws by the European Union, United States (including
								by the US Department of Commerce and US Department of Treasury),
								or United Kingdom (including by Her Majesty’s Treasury).
								Moreover, you represent that you are not located within any
								country or territory that is subject to comprehensive sanctions,
								including Iran, Syria, Lebanon, Cuba, North Korea, Crimea, or
								the so-called Donetsk People’s Republic or Luhansk People’s
								Republic.
							</p>

							<h3>11. Termination</h3>
							<p className="text-secondary">
								You agree that 1CISO.io, in its sole discretion, has the right
								to delete or deactivate your Account, block your e-mail or IP
								address to prevent you from accessing or using the Services, the
								1CISO.io Platform and the Website, or otherwise terminate your
								access to or use of the Services, the 1CISO.io Platform and the
								Website (or any part thereof), immediately and without notice,
								including, without limitation, if you breach any of these Terms
								of Use, or if you are judged bankrupt or insolvent, make a
								general assignment for the benefit of your creditors, a trustee
								or receiver is appointed for you; or any petition by or on your
								behalf is filed under any bankruptcy or similar laws. 1CISO.io
								shall not be liable to you or to any third-party for any
								termination of your Account and/or your access to the Services,
								to the 1CISO.io Platform and the Website. You agree not to
								attempt to use the Services the 1CISO.io Platform and the
								Website after any such termination. In the event that 1CISO.io
								terminates your Account and/or your access to the Services, the
								1CISO.io Platform and/or the Website for your breach, you will
								remain liable for all amounts due under these Terms of Use. You
								may cancel your Account at any time by informing us via the
								Website or by contacting customer support at help@1CISO.io, and
								providing proof of Account ownership. You acknowledge that in
								any event there will be no refunds of any pre-paid fees.
							</p>
							<p className="text-secondary">
								Once an Account is terminated or cancelled, 1CISO.io has no
								obligation to retain your User Data, and 1CISO.io may delete
								such User Data (including any configuration, customization and
								settings) without any option to recover the User Data. Note that
								in certain instances, some of your User Data may not be
								completely removed from the 1CISO.io Platform. We are not and
								shall not be responsible or liable for the removal or deletion
								of (or the failure to remove or delete) any of your User Data.
							</p>

							<h3>12. Survival</h3>
							<p className="text-secondary">
								Upon any termination, discontinuation or cancellation of
								Services or your Account, all provisions of these Terms of Use
								which by their nature should survive will survive, including,
								without limitation, sections hereof entitled “Ownership”,
								“Warranty Disclaimers”, “Indemnification”, “Limitation of
								Liability” and “Governing Law; Jurisdiction”.
							</p>

							<h3>13. Warranty Disclaimers</h3>

							<p className="text-secondary">
								YOU AGREE THAT USE OF THE SERVICES, THE 1CISO.IO PLATFORM AND
								THE WEBSITE IS ENTIRELY AT YOUR OWN RISK. THE SERVICES, THE
								1CISO.IO PLATFORM, THE WEBSITE AND THE DOCUMENTATION ARE
								PROVIDED ON AN “AS IS” OR “AS AVAILABLE” BASIS, WITHOUT ANY
								WARRANTIES OF ANY KIND. ALL EXPRESS AND IMPLIED WARRANTIES,
								INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY,
								FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF
								PROPRIETARY RIGHTS, AND ANY WARRANTIES ARISING FROM COURSE OF
								DEALING OR USAGE OF TRADE ARE EXPRESSLY DISCLAIMED TO THE
								FULLEST EXTENT PERMITTED BY LAW. TO THE FULLEST EXTENT PERMITTED
								BY LAW, 1CISO.IO DISCLAIMS ANY WARRANTIES FOR OTHER SERVICES OR
								GOODS ACCESSED THROUGH OR ADVERTISED ON THE WEBSITE, THE
								1CISO.IO PLATFORM OR SERVICES, OR ACCESSED THROUGH ANY LINKS
								THEREFROM.
							</p>

							<p className="text-secondary">
								1CISO.IO DOES NOT GUARANTEE OR ASSUME ANY RESPONSIBILITY OR
								LIABILITY THAT THE SERVICES, THE 1CISO.IO PLATFORM AND THE
								WEBSITE WILL ALWAYS FUNCTION WITHOUT DISRUPTIONS, DELAY OR
								ERRORS. 1CISO.IO DISCLAIMS ANY WARRANTY THAT THE SERVICES WILL
								BE ERROR FREE OR UNINTERRUPTED OR THAT ALL ERRORS WILL BE
								CORRECTED.
							</p>

							<p className="text-secondary">
								A number of factors may impact the quality of your
								communications and use of the Services, the Website and the
								1CISO.io Platform and may result in the failure of your
								communications including, without limitation, for example, your
								use of Shippers or other third party software, your local
								network, firewall, your internet service provider, the public
								internet and your power supply. 1CISO.IO TAKES NO RESPONSIBILITY
								FOR ANY DISRUPTION, INTERRUPTION OR DELAY CAUSED BY ANY FAILURE
								OF OR INADEQUACY IN ANY OF THESE ITEMS OR ANY OTHER ITEMS OVER
								WHICH WE HAVE NO CONTROL. YOU FURTHER ACKNOWLEDGE THAT 1CISO.IO
								IS NOT RESPONSIBLE AND HAS NO LIABILITY FOR ANY HARDWARE,
								SOFTWARE, OR OTHER ITEM OR SERVICE PROVIDED BY ANYONE OTHER THAN
								1CISO.IO.
							</p>

							<p className="text-secondary">
								YOU ACKNOWLEDGE THAT THE SERVICES AND DATA ARE HOSTED ON A THIRD
								PARTY HOSTING FACILITY, AND THAT 1CISO.IO SHALL NOT BE
								RESPONSIBLE FOR ANY INTERRUPTION TO THE SERVICES OR LOSS OF DATA
								WHICH IS CAUSED BY SUCH THIRD PARTY HOSTING FACILITY. 1CISO.IO
								MAY CHANGE THE THIRD PARTY HOSTING PROVIDER OR ITS LOCATION,
								FROM TIME TO TIME, AT ITS SOLE DISCRETION. YOU FURTHER
								ACKNOWLEDGE THAT 1CISO.IO IS NOT A BACKUP OR STORAGE SERVICE,
								AND THAT 1CISO.IO SHALL HAVE NO LIABILITY TOWARDS YOU WITH
								REGARD TO ANY LOSS OR DESTRUCTION OF USER DATA DURING THE
								RETENTION PERIOD. YOU ACKNOWLEDGE THAT 1CISO.IO DOES NOT WARRANT
								OR GUARANTY THE SECURITY OF THE WEBSITE, THE 1CISO.IO PLATFORM
								OR OF COMMUNICATIONS MADE USING THE SAME.
							</p>
							<p className="text-secondary">
								NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM
								1CISO.IO OR THROUGH THE WEBSITE AND/OR THE 1CISO.IO PLATFORM
								WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
							</p>

							<p className="text-secondary">
								You are solely responsible for all of your communications and
								interactions with other Users of the 1CISO.io Platform and with
								other persons with whom you communicate or interact as a result
								of your use of the 1CISO.io Platform. For example, Users may
								participate in a user-to-user forum. You understand that
								1CISO.io does not screen or inquire into the background of any
								users of the 1CISO.io Platform, nor does 1CISO.io make any
								attempt to verify the statements of Users. 1CISO.io makes no
								representations or warranties as to the conduct of Users or
								their compatibility with any current or future Users of the
								1CISO.io Platform. You should not provide your financial
								information (e.g. your credit card or bank account information)
								to other Users.
							</p>

							<h3>14. Indemnification</h3>
							<p className="text-secondary">
								You agree to defend, indemnify and hold 1CISO.io and their
								respective officers, directors, employees, agents and licensors
								harmless from and against any and all claims, damages, costs,
								liabilities and expenses (including reasonable attorneys’ fees)
								1CISO.io or any indemnified party suffers pursuant to, or in
								relation to, or arising from any violation by you of these Terms
								of Use, and/or any claim or demand by a third party that your
								use of the 1CISO.io Platform and/or the Services and/or the
								processing or usage of your User Data by 1CISO.io is in breach
								or violation of applicable laws or the right of any third party.
							</p>
							<h3>15. Limitation of Liability</h3>
							<p className="text-secondary">
								YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED
								BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF
								THE SERVICES, THE 1CISO.IO PLATFORM AND THE WEBSITE REMAINS WITH
								YOU. NEITHER 1CISO.IO NOR ANY OTHER PERSON OR ENTITY INVOLVED IN
								CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE
								FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR
								CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOST
								PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION,
								COMPUTER DAMAGE OR SYSTEM FAILURE, OR THE COST OF SUBSTITUTE
								PRODUCTS OR SERVICES ARISING OUT OF OR IN CONNECTION WITH THESE
								TERMS OF USE OR FROM THE USE OF OR INABILITY TO USE THE
								SERVICES, OR FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS
								WITH OTHER USERS OF THE WEBSITE, SERVICES OR OTHER PERSONS WITH
								WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE
								WEBSITE, THE 1CISO.IO PLATFORM OR SERVICES, WHETHER BASED ON
								WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT
								LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT 1CISO.IO
								HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A
								LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS
								ESSENTIAL PURPOSE.
							</p>
							<p className="text-secondary">
								IN NO EVENT WILL THE AGGREGATE LIABILITY OF 1CISO.IO, ITS
								AFFILIATES AND THEIR RESPECTIVE EMPLOYEES AND REPRESENTATIVES
								ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF USE OR FROM
								THE USE OF OR INABILITY TO USE THE SERVICES, THE WEBSITE OR THE
								1CISO.IO PLATFORM EXCEED: (A) THE AMOUNTS YOU HAVE PAID TO
								1CISO.IO FOR USE OF THE SERVICES DURING THE 12 MONTHS PRECEDING
								THE DATE THE LIABILITY FIRST ARISES, IF YOU ARE REGISTERED FOR A
								PAID PLAN; OR (B) FIFTY US DOLLARS ($50), IF YOU ARE REGISTERED
								FOR A TRIAL PLAN OR A COMMUNITY PLAN, AS APPLICABLE. THE
								LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS
								OF THE BASIS FOR THE AGREEMENT BETWEEN 1CISO.IO AND YOU.
							</p>
							<h3>16. Governing Law; Jurisdiction</h3>
							<p className="text-secondary">
								You agree that the laws of the State of Israel, without regard
								to principles of conflict of laws and regardless of your
								location, will govern these Terms of Use and any dispute of any
								sort that might arise between you and 1CISO.io and any of its
								affiliates. Any claim or dispute between you and 1CISO.io or any
								of its affiliates that arises in whole or in part from your use
								of the Services or the Website or your access to the 1CISO.io
								Platform shall be decided exclusively by a court of competent
								jurisdiction located in Tel-Aviv, Israel and you hereby consent
								to, and waive all defenses of lack of personal jurisdiction and
								forum non-convenient with respect to venue and jurisdiction in
								the courts of Tel-Aviv, Israel. YOU AND 1CISO.IO AGREE THAT ANY
								CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST
								COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES.
								OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
							</p>

							<h3>17. Integration and Severability</h3>
							<p className="text-secondary">
								These Terms of Use are the entire agreement between you and
								1CISO.io with respect to the use of the Services, the Website
								and the 1CISO.io Platform and supersede and replace all prior or
								contemporaneous communications and proposals, whether oral,
								written or electronic, between you and 1CISO.io with respect
								thereto. If any provision of these Terms of Use is found to be
								unenforceable or invalid, that provision will be limited or
								eliminated to the minimum extent necessary so that these Terms
								of Use will otherwise remain in full force and effect and
								enforceable. The failure of either party to exercise in any
								respect any right provided for herein shall not be deemed a
								waiver of any further rights hereunder. The waiver of any such
								right or provision will be effective only if in writing and
								signed by a duly authorized representative of 1CISO.io. Except
								as expressly set forth in these Terms of Use, the exercise by
								either party of any of its remedies under these Terms of Use
								will be without prejudice to its other remedies under these
								Terms of Use or otherwise.
							</p>

							<h3>18. Assignment</h3>
							<p className="text-secondary">
								You may not assign or transfer these Terms of Use, by operation
								of law or otherwise, without 1CISO.io’s prior express written
								consent. Any attempt by you to assign or transfer these Terms of
								Use, without such consent, will be null and of no effect.
								1CISO.io may assign or transfer these Terms of Use, in its sole
								discretion, without restriction. Subject to the foregoing, these
								Terms of Use will bind and inure to the benefit of the parties,
								their successors and permitted assigns.
							</p>

							<h3>19. Feedback</h3>
							<p className="text-secondary">
								We welcome feedback, comments and suggestions for improvements
								to the Services (“<b>Feedback</b>“). You can submit Feedback by
								e-mailing us via the Website or by contacting our customer
								support at help@1CISO.io. You grant to us a non-exclusive,
								worldwide, perpetual, irrevocable, fully-paid, royalty-free,
								sublicenseable and transferable license under any and all
								intellectual property rights that you own or control to use,
								copy, modify, create derivative works based upon and otherwise
								exploit the Feedback, whether provided directly to 1CISO.io, via
								our Website or by contacting our customer support, for any
								purpose.
							</p>

							<h3>20. Notices</h3>
							<p className="text-secondary">
								Any notices or other communications permitted or required
								hereunder, including those regarding modifications to these
								Terms of Use, will be in writing and given by us (i) via e-mail
								(in each case to the address that you provide), or (ii) by
								posting to the Website. For notices made by e-mail, the date of
								receipt will be deemed the date on which such notice is
								transmitted.
							</p>

							<h3>21. Publicity</h3>
							<p className="text-secondary">
								We may mention you as a client on the Website, in marketing
								collaterals and in public statements and for that purpose, we
								may use your logo and trademark. In addition, upon your written
								consent (not to be unreasonably withheld), we may issue a press
								release or case study, concerning our engagement with you.
							</p>

							<h3>22. Contact Us</h3>
							<p className="text-secondary">
								For any questions about these Terms of Use or any other issue
								regarding 1CISO.io or the Services please contact us via the
								Website or at: help@1CISO.io
							</p>
						</div>
					</div>
				</div>
				<Footer />
				<ChatHistory
					onNavigate={(path: string) => {
						navigate(path);
					}}
				/>
			</div>
		</section>
	);
};

export default TermsAndConditions;
