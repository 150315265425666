import path from "path";
import Pricing from "../components/common/pricing";
import Chat from "../components/private/chat";
import Feedback from "../components/private/feedback";
import ChangePassword from "../components/private/changePassword";
import UploadFiles from "../components/private/uploadFiles";
import IngestData from "../components/private/ingestData";
import ContactSales from "../components/private/contactSales";
import { useCases } from "../utils/constants";
import { Navigate } from "react-router-dom";
import MaturityAssessmentBody from "../components/private/maturityAssessment/maturityFrameworks";
import MaturityDomains from "../components/private/maturityAssessment/maturityDomains";
import MaturityAreas from "../components/private/maturityAssessment/maturityFlows";
import MaturityQuestionnaire from "../components/private/maturityAssessment/maturityQuestionnaire";
import AddData from "../components/private/addData";
import ViewFormData from "../components/private/viewFormData";

const privateRoutes = [
	{ path: "/", element: <Navigate to="/chat" /> },
	{ path: "/chat", element: <Chat /> },
	{
		path: useCases[0].route,
		element: <Chat useCase={useCases[0].value} />,
	},
	{
		path: useCases[1].route,
		element: <Chat useCase={useCases[1].value} />,
	},
	{
		path: useCases[2].route,
		element: <Chat useCase={useCases[2].value} />,
	},
	{
		path: useCases[3].route,
		element: <MaturityAssessmentBody />,
	},
	{
		path: `/maturityAssessment/:frameworkId/areas`,
		element: <MaturityDomains />,
	},
	{
		path: `/maturityAssessment/:frameworkId/areas/:domainId/questionflow`,
		element: <MaturityAreas />,
	},
	{
		path: `/maturityAssessment/:frameworkId/areas/:domainId/questionflow/:questionFlowId/questionnaire`,
		element: <MaturityQuestionnaire />,
	},
	{
		path: "/addData",
		element: <AddData />,
	},
	{
		path: "/viewFormData",
		element: <ViewFormData />,
	},
	{ path: "/feedback", element: <Feedback /> },
	{ path: "/pricing", element: <Pricing /> },
	{ path: "/changeAccountPassword", element: <ChangePassword /> },
	{ path: "/uploadFiles", element: <UploadFiles /> },
	{ path: "/ingest", element: <IngestData /> },
	{ path: "/contactSales", element: <ContactSales /> },
	//   { path: "/profile", component: Profile },
];

export default privateRoutes;
